import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  Alert,
  Typography,
  Grid,
} from '@mui/material';
import { ingresoUsuarioAccion, ingresoUsuarioLogin } from '../../redux/usuarioDucks';
import { useDispatch, useSelector } from 'react-redux';
import BtnAceceder from './BtnAceceder';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';


const FormLogin = (props) => {
  const dispatch = useDispatch();
  const resperror = useSelector((store) => store.usuario.error);

  const [email, setEmail] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(null);
  const [ userVerific , setUserVerific] = React.useState(false);

  const procesarDatos = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      setError('Ingrese Email');
      return;
    }
    if (!pass.trim()) {
      setError('Ingrese Contraseña');
      return;
    }
    if (pass.length < 5) {
      setError('Contraseña mayor a 6 caracteres');
      return;
    }

    if (resperror !== null) {
      setError('Error de acceso');
    }

    setError(null);
    Login();
  };

  const Login = React.useCallback(async () => {
    setSuccess(null);
    try {
      dispatch(ingresoUsuarioLogin(email, pass));
    } catch (error) {
      console.log(error);
    }
  }, [dispatch, email, pass]);

  const validarUsuario = async() => {
   
    try {
          const collRef = collection(db, "drivers");
    const q = query(
        collRef,
        where("email", "==", email),
      );
      const querySnapshot = await getDocs(q); 

      const result = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if(result.length > 0){

        if(result[0].active === true){
      
         setError(null)
         setUserVerific(true)
        }else{
        
            setError("Cuenta desactivada debe contactar soporte")
            setUserVerific(false)
        }
      }else{

      setError("No existe registro debe registrarse")
      setUserVerific(false)
      }
    } catch (error) {
      console.log(error)
    }


  }

  return (
    <Box
      sx={{
        maxWidth: 500,
        margin: 'auto',
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: 'white',
      }}
    >
      {/* <Typography variant="h5" gutterBottom align="center">
        Iniciar Sesión
      </Typography> */}
      <form onSubmit={procesarDatos}>
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="error">{error}</Alert>}
        {resperror && <Alert severity="error">{resperror}</Alert>}


        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="email"
              label="Correo electrónico"
              placeholder="email@dominio.com"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              
            />
          </Grid>
        {!userVerific ? 
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={()=> validarUsuario()}
              sx={{ mb: 2 }}
            >
              Continuar
            </Button>
          </Grid> : <></>}


        {userVerific ? <>  <Grid item xs={12}>
            <TextField
              fullWidth
              type="password"
              label="Contraseña"
              placeholder="*************"
              variant="outlined"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              sx={{ mb: 2 , mb:1}}
            >
              Entrar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              component={NavLink}
              to="/resset-pass"
               sx={{ mb: 2 , mb:1}}
            >
              Crear o recuperar contraseña
            </Button>
              <Button
              fullWidth
              variant="outlined"
              color="secondary"
              onClick={() => dispatch(ingresoUsuarioAccion())}
             
            >
              <img src="assets/Google__G__logo.svg.png" alt="google" style={{maxWidth: "20px", marginRight: "20px"}}/>     ACCEDER CON GOOGLE
              
            
            </Button>
          </Grid></> : <>  </>}
        

          
        </Grid>
      </form>
      <>
      {!userVerific ? 
      <Box mt={2}>
        <BtnAceceder />
      </Box>
      : <></>}
      </>

    </Box>
  );
};

export default FormLogin;
