import React, { useState } from "react";
import { Box, Typography, Divider, TextField, Button, Grid, CardContent, Card } from "@mui/material";
import moment from "moment";
import { translateStatesBicciFilter, translateStatesfalabbela } from "../../Utils/translateStates";

const OrdersDailyView = ({ groupsedByDay }) => {
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [filteredOrders, setFilteredOrders] = useState([]);

  // Filtrar las órdenes del día seleccionado
  const handleFilter = () => { 
    const selectedDayKey = moment(selectedDate).format("YYYY-MM-DD"); // Aseguramos el formato de la clave
  
    const ordersForDay = groupsedByDay[selectedDayKey] || []; // Buscar las órdenes por la clave

    setFilteredOrders(ordersForDay);
  };

  const truncateAddress = (address) => {
    if (!address) return "No especificado";
    const words = address.split(" ");
    return words.slice(0, 2).join(" ") + (words.length > 2 ? " ..." : "");
  };

  const OrdersList = ({ orders }) => {
    return (
      <Grid container spacing={2}>
        {orders.map((order) => {
          const dateToShow = order.updatedAt ?? order.createdAt;
          const dateFormatted = moment(
            dateToShow?.seconds ? dateToShow.seconds * 1000 : dateToShow
          ).format("DD/MM/YYYY HH:mm");

          return (
            <Grid item xs={12} key={order.id}>
              <Card>
                <CardContent>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold">
                        ID: {order.id}
                      </Typography>
                      <Typography variant="body2">Fecha: {dateFormatted}</Typography>
                      <Typography variant="body2">
                        Destino: {order.toAddress ?? "No especificado"}
                      </Typography>
                      <Typography variant="body2">
                        Estado: {translateStatesBicciFilter[order.status]}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        {translateStatesfalabbela[order.intState]}
                      </Typography>
                      <Typography variant="body2">
                        Observación: {order.observationAdmin}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <Box>
      <Box display="flex" justifyContent="center" my={2} gap={2}>
        <TextField
          label="Seleccionar fecha"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          inputProps={{
            max: moment().format("YYYY-MM-DD"), // Máximo: Hoy
            min: moment().subtract(30, "days").format("YYYY-MM-DD"), // Mínimo: Hace 30 días
          }}
        />
        <Button
          variant="contained"
          onClick={handleFilter}
          sx={{ alignSelf: "center" }}
        >
          Filtrar
        </Button>
      </Box>

      {filteredOrders.length === 0 ? (
        <Typography>No hay órdenes para la fecha seleccionada.</Typography>
      ) : (
        <Box mb={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Órdenes del {moment(selectedDate).format("DD/MM/YYYY")}
          </Typography>
          <OrdersList orders={filteredOrders} />
          <Divider sx={{ mt: 2, mb: 2 }} />
        </Box>
      )}
    </Box>
  );
};

export default OrdersDailyView;
