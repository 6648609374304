import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Divider,
  Card,
  CardContent,
  Grid,
  Switch,
  FormControlLabel,
  Dialog
} from "@mui/material";
import { Icon, Select } from "semantic-ui-react";
import moment from "moment";
import { useDispatch } from "react-redux";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Timestamp } from "firebase/firestore";
import { noRetornoEstados, translateStatesfalabbela } from "../../Utils/translateStates";
import { assingMultipleDriver } from "../../Utils/assingMultipleDriver";
import { registerPickerActions } from "../../Utils/registerPickerActions";
import RejectionDialog from "./RejectionDialog";
import { de } from "date-fns/locale";

const BuscarRutas = ({userUid}) => {
  const [searchRoute, setSearchRoute] = useState("");
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [originalFilteredRoutes, setOriginalFilteredRoutes] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [filterText, setFilterText] = useState(""); // Campo de texto para el filtro dinámico
  const [dialogOpen, setDialogOpen] = useState(false);
   const [selectedOrderId, setSelectedOrderId] = useState(null);

  const dispacth = useDispatch();

  // Función para manejar el click en "Buscar"
  const handleSearch = async () => {
    if (!searchRoute.trim()) {
      alert("Ingresa un ID de ruta válido.");
      return;
    }

    const toDate = new Date();
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 5);

    const fromTimestamp = Timestamp.fromDate(fromDate);
    const toTimestamp = Timestamp.fromDate(toDate);

    try {
      const collRef = collection(db, "orders");
      const qSearch = query(
        collRef,
        where("driverLicensePlate", "==", searchRoute),
        where("createdAt", ">=", fromTimestamp),
        where("createdAt", "<=", toTimestamp)
      );

      const querySnapshot = await getDocs(qSearch);
      const ordersFound = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const validOrders = ordersFound.filter(
        (order) => order.status < 8 && order.status > 0
      );

      setFilteredRoutes(validOrders);
      setOriginalFilteredRoutes(validOrders);
    } catch (error) {
      console.error("Error buscando órdenes:", error);
    }
  };

  // Función para manejar el filtro dinámico
  const handleFilterTextChange = (event) => {
    const value = event.target.value.toLowerCase();
    setFilterText(value);

    if (!value) {
      // Si el filtro está vacío, muestra todos los resultados originales
      setFilteredRoutes(originalFilteredRoutes);
      return;
    }

    // Filtrar por texto (puedes ajustar los campos según tu lógica)
    const filtered = originalFilteredRoutes.filter(
      (order) =>
        order.toAddress?.toLowerCase().includes(value) || // Filtra por dirección
        order.id?.toLowerCase().includes(value) // Filtra por ID de orden
    );

    setFilteredRoutes(filtered);
  };

  // Maneja el cambio de estado del Switch
  const handleToggleCheck = (orderId, isChecked) => {



    if (isChecked) {
              
              // quierro crear el documento de lo Pickup dia_ID_conductor 
              // si existe no lo creo , lo actualizo
              /** datos basicos. 
               *orderID: dataID,
                date: new Date(),
                driverLicensePlate: data.driverLicensePlate || "null",
                routePosition: data.routePosition || "null",
                user: user.uid || "",
                timeStamp: new Date(),
               * 
               *  */  
      
              // y luego actualizar el estado de la orden
              // y luego actualizar el estado de la orden
              // primero await updateDoc(orderRef, { isPickedUp: true });

      setSelectedOrders((prev) => [...prev, orderId]);
    } else {
      setSelectedOrders((prev) => prev.filter((id) => id !== orderId));
    }
  };

  const handleAsignar = (orderId) => {  
    
     let ordersForAccions = [orderId];
     var dataAsid = {
      data: {
        driverID: userUid,
        orderIDs: ordersForAccions,
      },
    };

    dispacth(assingMultipleDriver(dataAsid))

  }

  const handleRechazar = (orderId , reason) => {

     let ordersForAccions = [orderId];
     var dataAsid = {
      data: {
        driverID: userUid,
        orderIDs: ordersForAccions,
        reason: reason,
      },
    };


    dispacth(registerPickerActions(dataAsid))

  }

    const handleOpenDialog = (orderId) => {
    setSelectedOrderId(orderId); // Guardamos el ID seleccionado
    setDialogOpen(true); // Abrimos el diálogo
  };


  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Buscar Rutas
      </Typography>

      {/* Búsqueda por ID de Ruta */}
      <Box mb={2}>
        <TextField
          label="Ruta"
          variant="outlined"
          value={searchRoute}
          onChange={(e) => setSearchRoute(e.target.value)}
          sx={{ mr: 2 }}
        />
        <Button onClick={handleSearch}>
          <Icon />
          Buscar Ruta
        </Button>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Filtro dinámico por texto */}
      <Box mb={2}>
        <Typography variant="subtitle1" gutterBottom>
          Filtrar por texto
        </Typography>
        <TextField
          label="Buscar en órdenes"
          variant="outlined"
          fullWidth
          value={filterText}
          onChange={handleFilterTextChange}
        />
      </Box>

      {/* Listado de Órdenes Filtradas */}
      {filteredRoutes.length === 0 ? (
        <Typography>No se encontraron órdenes en los últimos 5 días.</Typography>
      ) : (
        <Grid container spacing={2}>
          {filteredRoutes.map((order) => {
            const dateToShow = order.createdAt ?? order.updatedAt;
            const dateFormatted = moment(
              dateToShow?.seconds ? dateToShow.seconds * 1000 : dateToShow
            ).format("DD/MM/YYYY HH:mm");

            const isChecked = selectedOrders.includes(order.id);

            const determineDisable = () => {
              if(noRetornoEstados.includes(order.intState)){
                  return true
              }
              if(order.driverID === userUid){
                return true
              }
               
            }
            const disabled = determineDisable()

            return (
              <Grid item xs={12} key={order.id}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight="bold">
                      ID Orden: {order.id}
                    </Typography>
                    <Typography variant="body2">
                      Fecha: {dateFormatted}
                    </Typography>
                    <Typography variant="body2">
                      Destino: {order.toAddress ?? "No especificado"}
                    </Typography>

                    <Typography variant="body2">
                      {translateStatesfalabbela[order.intState] || order.intState}
                    </Typography>
                    <Typography variant="body2">
                      Observación: {order.observationAdmin}
                    </Typography>
                    <Box 
                    sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {/* Switch para marcar la orden */}
                    {/* <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={isChecked}
                          onChange={(event) =>
                            handleToggleCheck(order.id, event.target.checked)
                          }
                        />
                      }
                      label="Marcar recogida / verificada"
                    /> */}
                     <Typography variant="subtitle1" fontWeight="bold">
                      {order.driverID === userUid ? "Asignado a tu usuario" :  
                      <Button
                      variant="contained"
                      disabled={ disabled}   
                      onClick={()=>{ handleAsignar(order.id)}}>
                        Asignarme
                    </Button>}
                    </Typography>
                     
                      <Button
                      variant="contained"
                      sx={{backgroundColor: 'red', color: 'white'}}
                      disabled={ noRetornoEstados.includes(order.intState) ? true : false}   
                      onClick={()=> handleOpenDialog(order.id)}>
                        Rechazo
                    </Button>
{/* handleRechazar(order.id) */}
                     
                  
                    </Box>

                  
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
             <RejectionDialog
                          open={dialogOpen}
                          onClose={() => setDialogOpen(false)}
                          orderId={selectedOrderId}
                          handleRechazar={(id, reason) => { handleRechazar(id , reason)}}
                        />
        </Grid>
      )}
    </Box>
  );
};

export default BuscarRutas;
