import React, {useEffect} from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Divider } from 'semantic-ui-react';
import useRutasDelDia from '../../../../hook/useRutasDelDia';
import { useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
// Si vas a usar react-beautiful-dnd:
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { translateStatesBicci } from '../../../../Utils/translateStates';
import { TextField } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
// Primero importa firestore si no lo tienes ya:
import { doc, deleteDoc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../../../firebase"; // ajusta la ruta si es necesario




export default function ListadoDeOrdenes() {
const userUid = useSelector((store) => store.usuario?.user?.uid);
const navigate = useNavigate()
const { dataInfo } = useRutasDelDia(userUid);

console.log("dataInfo", dataInfo)

    const [orders, setOrders] = React.useState([])

//buscador
    const [searchTerm, setSearchTerm] = React.useState('');
    const [matchedIndexes, setMatchedIndexes] = React.useState([]);
    const [matchPointer, setMatchPointer] = React.useState(0);

    const orderRefs = React.useRef({});
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const location = useLocation();
    const orderToFocus = location.state?.orderToFocus;

    const [ selectedOrder , setSelectedOrder ] = React.useState(null)

    const [orderIteration , setOrederIteractions]= React.useState(null);

    const [copyAlert, setCopyAlert] = React.useState(false);

    useEffect(()=>{
          setOrederIteractions(orderToFocus)
    }, [orderToFocus])

    // useEffect(()=>{
        
    //    setSelectedOrder(orderIteration)
    
    // }, [orderIteration])

    useEffect(() => {
    if (orderIteration ) {
        const index = orders.findIndex((o) => o.id === orderIteration);
        setSelectedOrder(orderIteration)
        setCurrentIndex(index);
        setTimeout(() => {
        if(orderRefs.current[orderIteration]){
        orderRefs.current[orderIteration].scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
        }
        }, 300); // 🔄 Pequeño delay para asegurar que todo está renderizado
    }
    }, [orderIteration])
 

    useEffect(() => {
    if (dataInfo?.orders) {
      
        setOrders(
        dataInfo.orders.sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
        );
    }
    }, [dataInfo]); // <---- ya no [orders]


  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => setActiveStep((prev) => prev + 1);
  const handleBack = () => setActiveStep((prev) => prev - 1);
  const handleReset = () => setActiveStep(0);

  // 🔹 Colores según el estado de la orden
  const colorByStatus = {
    "-1": "#808080",
    0: "#6993a3",
    1: "#8dcfe9",
    2: "#1aa7df",
    3: "#7f8fe3",
    4: "#687de9",
    5: "#455edf",
    6: "#1231d5",
    7: "#63d77d",
    8: "#08a12b",
    9: "#08a12b",
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updated = Array.from(orders);
    const [movedItem] = updated.splice(result.source.index, 1);
    updated.splice(result.destination.index, 0, movedItem);
    setOrders(updated);
    setSelectedOrder(null);
  };

  const handleSearch = (term) => {
  setSearchTerm(term);

  if (!term) {
    setMatchedIndexes([]);
    return;
  }

  const matches = orders
    .map((order, index) => ({ id: order.id.toLowerCase(), index }))
    .filter((entry) => entry.id.includes(term.toLowerCase()))
    .map((entry) => entry.index);

    setMatchedIndexes(matches);
    setMatchPointer(0);

    if (matches.length > 0) {
        scrollToIndex(matches[0]);
        setCurrentIndex(matches[0]);
    }
    };

    const scrollToIndex = (index) => {
    const id = orders[index]?.id;
    const element = orderRefs.current[id];
    if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    };

    const handleCopy = (step) => {
  const textToCopy = `ID: ${step.id}\nMotivo:`;
  
  navigator.clipboard.writeText(textToCopy)
    .then(() => {
      setCopyAlert(true); // ✅ Activar la alerta
      setTimeout(() => setCopyAlert(false), 1000); // ⏱️ Cerrar en 1 segundo
    })
    .catch((err) => {
      console.error('Error al copiar al portapapeles:', err);
    });
    };


    // Requiere que "step.routeName" esté definido si ya viene del merge
    const addToRoute = async (order) => {
    try {
        if (!order?.id || !order?.driverID || !order?.toAddressLocation) return;

        const chileDate = new Date(
        new Date().toLocaleString("en-US", { timeZone: "America/Santiago" })
        );
        const dateStamp = chileDate.toISOString().slice(0, 10).replace(/-/g, "");

        const routeID = `${dateStamp}_${order.driverID}_Ruta_1`; // o busca tu ruta activa si ya existe

        const waypoint = {
        id: order.id,
        ids: [order.id],
        routeName: routeID,
        driverID: order.driverID,
        currentStatus: order.status,
        location: {
            lat: order?.toAddressLocation?.latitude ?? 0,
            lon: order?.toAddressLocation?.longitude ?? 0,
        },
        trackingData: {
            creationDateFormated: dateStamp,
            creationTime: Timestamp.now(),
        },
        };

        await setDoc(doc(db, "xpress_deliveryPlans", routeID, "waypoints", order.id), waypoint);
        console.log("✅ Agregado a ruta", routeID);
    } catch (e) {
        console.error("❌ Error al agregar a la ruta", e);
    }
    };

    const removeFromRoute = async (order) => {
    try {
        if (!order?.id || !order?.routeName) return;
        await deleteDoc(doc(db, "xpress_deliveryPlans", order.routeName, "waypoints", order.id));
        console.log("🗑️ Parada eliminada de la ruta", order.routeName);
    } catch (e) {
        console.error("❌ Error al eliminar parada", e);
    }
    };



   

  return (
    <Box sx={{ width: "100%", padding: "10px", pb: "80px" }}>
      <Box
        sx={{
            position: 'fixed',
            top: '80px',
            left: 0,
            width: '100%',
            px: 2,
            py: 1,
            backgroundColor: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: 3,
            zIndex: 300,
            gap: 1,
        }}
        >
        {/* Input de búsqueda */}
        <TextField
            size="small"
            placeholder="Buscar ID"
            onChange={(e) => handleSearch(e.target.value)}
            sx={{
            width: 180,
            backgroundColor: '#f9f9f9',
            borderRadius: 1,
            }}
        />

        {/* Flechas de navegación */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Button
            disabled={matchPointer <= 0}
            onClick={() => {
                const newPointer = matchPointer - 1;
                setMatchPointer(newPointer);
                scrollToIndex(matchedIndexes[newPointer]);
                setCurrentIndex(matchedIndexes[newPointer]);
            }}
            size="small"
            sx={{ minWidth: '32px', p: 0, fontSize: '1rem' }}
            >
            ⬆️
            </Button>

            <Button
            disabled={matchPointer >= matchedIndexes.length - 1}
            onClick={() => {
                const newPointer = matchPointer + 1;
                setMatchPointer(newPointer);
                scrollToIndex(matchedIndexes[newPointer]);
                setCurrentIndex(matchedIndexes[newPointer]);
            }}
            size="small"
            sx={{ minWidth: '32px', p: 0, fontSize: '1rem' }}
            >
            ⬇️
            </Button>
        </Box>

        {/* Total de asignados */}
        <Typography fontWeight="bold" fontSize="0.9rem">
            {orders.length} Asignados
        </Typography>
        </Box>


      {/* Contenedor principal con padding top para que no quede tapado por la barra */}
       <Box sx={{ width: "100%", padding: "10px", pb: "80px" ,   pt:"80px",}}>
        
        {/* Ejemplo con react-beautiful-dnd */}
        <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="vertical-list" direction="vertical">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {/* Mapeo vertical de tus órdenes */}
                {orders.map((step, index) => (
                <Box key={step.id}  ref={(el) => (orderRefs.current[step.id] = el)} sx={{ display: 'flex', position: 'relative' }}>   
                    <Box sx={{ width: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
                    <Box
                        sx={{
                        width: 24,
                        height: 24,
                        top:"45%",
                        borderRadius: '50%',
                        backgroundColor: step.currentStatus === 8 ? colorByStatus[step.currentStatus] : 'primary.main',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '12px',
                        zIndex: 1,
                        position: "absolute",
                        }}
                    >
                        {index + 1}
                            </Box>
                            {index < orders.length - 1 && (
                                <Box
                                sx={{
                                    width: '2px',
                                    top:"45%",
                                    flex: 1,
                                    backgroundColor: step.currentStatus === 8 ? colorByStatus[step.currentStatus] : '#ccc',
                                    marginTop: '2px',
                                }}
                                />
                            )}
                            </Box>
                            <Draggable
                            key={step.id}
                            draggableId={step.id}
                            index={index}
                            >
                            {(draggableProvided) => (
                                <Box
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                {...draggableProvided.dragHandleProps}
                                sx={{
                                    mb: 2, // separa cada "paso" vertical
                                    border: selectedOrder === step.id ? "4px solid blue" : '1px solid #ccc',
                                    borderRadius: 1,
                                    borderTopLeftRadius:5,
                                    borderBottomLeftRadius:5,
                                    borderTopRightRadius:15,
                                    borderBottomRightRadius:15,
                                    p: 2,
                                    position: 'relative',
                                    backgroundColor: "#fff",
                                    width: "100%",
                                    boxShadow: "10px 10px 5px -7px rgba(0,0,0,0.75)"

                                }}
                                onClick={ ()=> setSelectedOrder(step.id)}
                                
                                
                                >
                              
                                <Button sx={{ 
                                        color: colorByStatus[step.status], 
                                        border: "1px solid #ededed", 
                                        mt:1,
                                        mb:1,
                                        padding: "4px",
                                        width: "fit-content",
                                        boxShadow: "7px 11px 7px -11px rgba(5,1,5,1)"
                                    }}
                                    onClick={() => handleCopy(step)}
                                    >
                                    <strong>ID:</strong> {step.id}
                                </Button>
                               
                               <Snackbar
                                open={copyAlert}
                                message="📋 Datos copiados"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' , backgroundColor:  "#741ab0"  }}
                                 sx={{
                                    bottom: 80,
                                    '& .MuiSnackbarContent-root': {
                                    backgroundColor: '0px 4px 10px rgba(116, 26, 176, 1)',
                                    color: '#fff',
                                    borderRadius: '8px',
                                    fontSize: '0.85rem',
                                    padding: '6px 16px',
                                    boxShadow: '0px 4px 10px rgba(116, 26, 176, 0.43)',
                                    },
                                }}
                                />

                                {step.currentStatus === 8 ? <></>: 
                                <Typography variant="body2" sx={{fontSize:"0.6em"}}>
                                    {step.toAddress} <strong>Nombre:</strong> {step.userName} : <strong>Teléfono:</strong> {step.userPhone}
                                </Typography> }                            
                                 
                                 <Typography variant="body2" sx={{ 
                                        fontSize:"0.6em",
                                        mt:1,
                                        mb:1,
                                    }
                                        }>
                                    <strong>Parada:</strong> { step.index} 
                                 </Typography>
                             
                            
                                 <Typography variant="body2" sx={{ 
                                        color: colorByStatus[step.status || step.currentStatus ], 
                                        fontSize:"0.6em",
                                        mt:1,
                                        mb:1,
                                    }
                                        }>
                                    <strong>Estado:</strong> { translateStatesBicci[step.status || step.currentStatus]} 
                                </Typography>
                                { step.currentStatus !== 8 && step.intState ? 
                                  <Typography variant="body2" sx={{ 
                                        color: "orange", 
                                        fontSize:"0.6em",
                                        mt:1,
                                        mb:1,
                                    }
                                        }>
                                    <strong>Repite COD  { step.intState } </strong>
                                </Typography> : <></>}
                                <Typography variant="body2" 
                                    sx={{ 
                                        fontSize:"0.6em",
                                        mt:1,
                                        mb:1,
                                    }}
                                    >
                                    { step.observationAdmin ? step.observationAdmin[step.observationAdmin.length - 1] : ""}
                                </Typography>
                                {step.waypointInfo? <Button onClick={()=> removeFromRoute(step)}  sx={{ color: "red" , fontSize: "0.6em" , p:0}}> Eliminar Parada</Button>: <Button onClick={()=> addToRoute(step)}  sx={{ color: "green" , fontSize: "0.6em" , p:0}}> Agregar Parada</Button>}
  
                                <Box 
                                     sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 8,
                                    bottom: 8,
                                    fontSize: "0.6em"
                                    }}

                                    >
                                    <Button
                                    size="small"
                                    variant="outlined"
                                    onClick={() => navigate('/route', { state: { orderToFocus: step } })}
                                    sx={{ fontSize: "0.8em"}}
                                    >
                                    🗺️ Ver en Mapa
                                    </Button>
                                </Box>

                             
                                <Box
                                    sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 8,
                                    top: 8
                                    }}
                                >
                                    {step.toAddressLocation && (
                                    <>
                                        <a
                                        href={`https://waze.com/ul?ll=${step.toAddressLocation.latitude},${step.toAddressLocation.longitude}&navigate=yes`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="Waze"
                                        >
                                        <img
                                            src="assets/waze.png"
                                            alt="Waze"
                                            style={{ maxWidth: "32px", marginRight: "2px" }}
                                        />
                                        </a>
                                        <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${step.toAddressLocation.latitude},${step.toAddressLocation.longitude}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        alt="Google Maps"
                                        >
                                        <img
                                            src="assets/googlemaps.png"
                                            alt="Google Maps"
                                            style={{ maxWidth: "28px", marginRight: "2px" }}
                                        />
                                        </a>
                                    </>
                                    )}
                                </Box>
                           

                                

                                {/*  
                                <Box sx={{ mt: 2 }}>
                                    <Typography variant="body3">
                                    <strong>Confimar parada:</strong>
                                    </Typography>
                                    <Box sx={{ mt: 1 }}>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1, backgroundColor: "orange", padding: "3px" }}
                                    >
                                        {'SI'}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1, padding: "3px" }}
                                    >
                                        {index === orders.length - 1 ? 'Finalizar' : 'NO'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Atrás
                                    </Button>
                                    </Box>
                                    
                                </Box>
                                    */}

                                
                                </Box>
                            )}
                            </Draggable>
                     </Box>
                  ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

      
        {/* {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>Ruta completada</Typography>
            <Button onClick={handleReset} sx={{ mt: 1 }}>
              Reiniciar
            </Button>
          </Paper>
        )} */}
      </Box>

      {/* Barra fija abajo */}
     <Box
        sx={{
          position: 'fixed',
          bottom: 55,
          left: 0,
          width: '100%',
          p: 0,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'space-between',
          boxShadow: 3,
          zIndex: 300,
        }}
      >
        <Button onClick={()=> console.log("editar")}>Editar</Button>
        <Button onClick={()=> console.log("calcular")}>Crear Ruta</Button>
      </Box> 
    </Box>
  );
}
