import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

export const registerPickerActions = (dataAsid, user) => async (dispatch) => {
  try {


    // Crear el identificador único para el documento usando la fecha actual y el ID del conductor
    const today = new Date();
    const newDatePicking = `${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}`;
    const documenID = `${dataAsid.data.driverID}_${newDatePicking}`;


    // Referencia del documento en la colección
    const orderRefPicking = doc(
      db,
      process.env.REACT_APP_COL_PICKER_REGISTER,
      documenID
    );

    // Obtener el documento actual
    const docSnapshotPicking = await getDoc(orderRefPicking);

    if (docSnapshotPicking.exists()) {
      // Si el documento ya existe, actualizar o agregar un nuevo subdocumento
 

      //const subdocumentId = Date.now().toString();
      const newScan = {
        [dataAsid.data.orderIDs[0]]: {
          id: dataAsid.data.orderIDs[0],
          date: new Date(),
          user: dataAsid.data.driverID || "",
          isPickedUp: true,
          reject: true,
          reason: dataAsid.data.reason,
        },
      };

      try {
        // Actualizar el documento existente con el nuevo escaneo
        await updateDoc(orderRefPicking, newScan);
    
      } catch (error) {
        console.error("Error al agregar subdocumento:", error);
      }
    } else {
      // Si el documento no existe, crearlo
      try {
       
        await setDoc(orderRefPicking, {
          id: documenID,
          orderID: documenID,
          date: new Date(),
          user: dataAsid.data.driverID || "",
          timeStamp: new Date(),
          [dataAsid.data.orderIDs[0]]: {
          id: dataAsid.data.orderIDs[0],
          date: new Date(),
          user: dataAsid.data.driverID || "",
          isPickedUp: true,
          reject: true,
          reason: dataAsid.data.reason,
        },
        });
     
      } catch (error) {
        console.error("Error al crear documento:", error);
      }
    }

    // Verificar y actualizar el estado de la orden en la colección ORDERS
    const orderId = dataAsid.data.id || dataAsid.data.orderID;
    const orderRef = doc(db, process.env.REACT_APP_COL_ORDERS, orderId.toString());
    const docSnapshot = await getDoc(orderRef);

    if (docSnapshot.exists()) {
      try {
        // Actualizar el estado de la orden a 'isPickedUp: true'
        await updateDoc(orderRef, { isPickedUp: true });
     
      } catch (error) {
        console.error("Error al actualizar orden:", error);
      }
    } else {

    }
  } catch (error) {
    console.error("Error en registerPickerActions:", error);
  }
};
