import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import MapaOrdersPendientesView from "../../../../components/MapaOrdersPendientes/MapaOrdersPendientesView";

const MapaViajesActivos = () => {
   
   const [ selectionOrderData, setOrderData]  = useState(false);
   const { ordersHistory } = useOutletContext();

  return (
    <MapaOrdersPendientesView orders={ordersHistory.pendientes} setOrderData={setOrderData} />
  );
};

export default MapaViajesActivos;
