import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, useMap, Marker, Popup, Circle, Tooltip, CircleMarker , Polyline  } from "react-leaflet";
import useRutasDelDia from "../../../../hook/useRutasDelDia";
import { Avatar, Box,  Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ControlPanel from "../ControlPanel";
import ConfigPanel from "../ConfigPanel";
import useUserConfig from "../../../../hook/useUserConfig";
import "leaflet/dist/leaflet.css";
import ItemsPopup from "../ItemsPopup";
import "leaflet-rotate";
import L from "leaflet";
import { useLocation, useNavigate } from "react-router-dom";

const MapHelper = () => {
  useMap();
  return null;
};

const RotateControl = ({ angle }) => {
  const map = useMap();
  map.options.rotate = true;
  map.touchRotate.enable();

  useEffect(() => {
    if (map && map.setBearing) {
      map.setBearing(angle);
    } else {
      console.warn('La función setBearing no está disponible en el mapa.');
    }
  }, [map, angle]);

  return null;
};



const MapComponentMonitorStatusLeaflet = () => {
   const userUid = useSelector((store) => store.usuario?.user?.uid);
   const navigate = useNavigate()
   const { userConfig, updateUserConfig } = useUserConfig(); 
   const location = useLocation();
   const orderToFocus = location.state?.orderToFocus;

  const [ selectedViewLinesRider , setSelectedViewLinesRider] = useState([])
  const [ selectedViewRouteRiderData , setSelectedViewRouteRiderData]= useState([])

  const [selectedOrder, setSelectedOrder] = useState(null);

  const [visible , setVisible] = useState({
    visibleControl: false,
    visibleConfig: false,
  })

  const [ routeGeometryRider , setRouteGeometryRider]  = useState([])
  const [ selectedViewRouteRider , setSelectedViewRouteRider] = useState([])
  const { dataInfo } = useRutasDelDia(userUid);

  const [orderIteration , setOrederIteractions]= useState(null);


  const mapsTylers = [
    {
      id: 1,
      name: "Google Maps",
      url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&key=TU_API_KEY",
      attribution: "© Google Maps",
    },
    // {
    //   id: 2,
    //   name: "OpenStreetMap Grey",
    //   url: "https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",
    //   attribution: "© OpenStreetMap contributors, Stadia Maps",
    // },
    // {
    //   id: 3,
    //   name: "OpenStreetMap Dark",
    //   url: "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
    //   attribution: "© OpenStreetMap contributors, Stadia Maps",
    // },
    {
      id: 4,
      name: "OpenStreetMap Light All",
      url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
      attribution: "© OpenStreetMap contributors, Stadia Maps",
    }
  ];


  const [rotation, setRotation] = useState(0);

  

    useEffect(() => {
      if (dataInfo?.routes?.length > 0) {
        // 🔹 Comprobar si los datos ya están en el estado para evitar cambios innecesarios
        if (JSON.stringify(selectedViewLinesRider) !== JSON.stringify(dataInfo.routes)) {
          setSelectedViewLinesRider(dataInfo.routes);
          setSelectedViewRouteRiderData(dataInfo.routes);
          
          // 🔹 Si no hay rutas seleccionadas, seleccionar la primera
          if (selectedViewRouteRider.length === 0) {
            setRouteGeometryRider(dataInfo.routes.map(route => route.id));
            setSelectedViewRouteRider([dataInfo.routes[0]?.id]);
          }
        }
      }
    }, [dataInfo]);

    useEffect(()=>{
    if(selectedOrder){
    handleToggleVisibility(null)
    }
    },[selectedOrder ])

    useEffect(()=>{
      setOrederIteractions(orderToFocus)
    }, [orderToFocus])

    useEffect(()=>{
    
      setSelectedOrder(orderIteration)

    }, [orderIteration])

    const FlyToOrder = ({ order }) => {
      const map = useMap();
      const lastOrderId = useRef(null);

      useEffect(() => {
        if (
          order?.id &&
          order?.toAddressLocation &&
          order.id !== lastOrderId.current // 🔐 Solo si es diferente
        ) {
          const lat = order.toAddressLocation.latitude ?? order.toAddressLocation._lat;
          const lon = order.toAddressLocation.longitude ?? order.toAddressLocation._long;

          if (!isNaN(lat) && !isNaN(lon)) {
            map.flyTo([lat, lon], 17); // ✅ Zoom al nuevo
            lastOrderId.current = order.id; // 🧠 Guardar el último ID al que hicimos zoom
          }
        }
      }, [order, map]);

      return null;
    };

   

  const handleRouteSelection = (event) => {
    const selectedRoutes = event.target.value;
    setSelectedViewRouteRider(selectedRoutes);
  };
 

  // 🔹 Colores según el estado de la orden
  const colorByStatus = {
    "-1": "#808080",
    0: "#6993a3",
    1: "#8dcfe9",
    2: "#1aa7df",
    3: "#7f8fe3",
    4: "#687de9",
    5: "#455edf",
    6: "#1231d5",
    7: "#63d77d",
    8: "#08a12b",
    9: "#08a12b",
  };

  const createSVGIcon = (color, strokeWidth = 9) => {
    return L.divIcon({
      className: "",
      html: `
        <svg width="27" height="25" viewBox="0 0 395.71 395.71" xmlns="http://www.w3.org/2000/svg">
          <g>
            <path d="M197.849,0C122.131,0,60.531,61.609,60.531,137.329c0,72.887,124.591,243.177,129.896,250.388l4.951,6.738
              c0.579,0.792,1.501,1.255,2.471,1.255c0.985,0,1.901-0.463,2.486-1.255l4.948-6.738c5.308-7.211,129.896-177.501,129.896-250.388
              C335.179,61.609,273.569,0,197.849,0z"
              fill="${color}" stroke="black" stroke-width="${strokeWidth}"/>
            <circle cx="197.849" cy="137.329" r="49.191" fill="#fff" stroke="black" stroke-width="3"/>
          </g>
        </svg>
      `,
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
    });
  };

  // 🔹 Iconos personalizados
  const customIcon = new L.Icon({
    iconUrl: "/assets/conductor.png",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  const homeIcon = L.divIcon({
    className: "custom-icon",
    html: "🏠",
    iconSize: [30, 30],
    iconAnchor: [15, 30],
  });

  const handleToggleVisibility = (item) => {
    if(item){
      setSelectedOrder(null)
    }
    setVisible((prev) => ({
      visibleControl: item === "control" ? !prev.visibleControl : false,
      visibleConfig: item === "config" ? !prev.visibleConfig : false,
    }));
  }

  const rotateMap = (angle) => {
    setRotation((prev) => (prev + angle) % 360); // Asegurar que no supere 360°
  };

  const buttonStyle = {
    padding: '8px 15px',
    margin: '5px',
    border: 'none',
    borderRadius: '4px',
    background: '#4CAF50',
    color: 'white',
    cursor: 'pointer',
    boxShadow: '0 2px 5px rgba(0,0,0,0.2)'
  };

  const handleNavigate = ()=>{
    navigate('/lista', { state: { orderToFocus: selectedOrder.id } })
    setSelectedOrder(null)
  }

  const rotationAngle = 45;

  return (
    <Box style={{ height: "80vw", width: "100vw", pt: "height: '80px'," }}>
     

  
      <ControlPanel
        showRoutes={userConfig.showRoutes}
        setShowRoutes={(value) => updateUserConfig("showRoutes", value)}
        showWaypoints={userConfig.showWaypoints}
        setShowWaypoints={(value) => updateUserConfig("showWaypoints", value)}
        showDrivers={userConfig.showDrivers}
        setShowDrivers={(value) => updateUserConfig("showDrivers", value)}
        selectedViewRouteRider={selectedViewRouteRider}
        setSelectedViewRouteRider={setSelectedViewRouteRider}
        handleRouteSelection={handleRouteSelection}
        routeGeometryRider={routeGeometryRider}
        visibleControl={visible.visibleControl}
        handleToggleVisibility={handleToggleVisibility}
      />

      <ConfigPanel
        showItemsPopup={userConfig.showItemsPopup}
        setShowItemsPopup={(value) => updateUserConfig("showItemsPopup", value)}
        showItemsCant={userConfig.showItemsCant}
        setShowItemsCant={(value) => updateUserConfig("showItemsCant", value)}
        selectedMapTyler={userConfig.selectedMapTyler}
        setSelectedMapTyler={(value) => updateUserConfig("selectedMapTyler", value)}
        visibleConfig={visible.visibleConfig}
        handleToggleVisibility={handleToggleVisibility}
        mapsTylers={mapsTylers}
      />
      {/* <div>
      <button 
        onClick={() => setRotation(prev => prev + 45)}
        style={buttonStyle}
      >
        ↻ 45°
      </button>
      <button 
        onClick={() => setRotation(prev => prev - 45)}
        style={buttonStyle}
      >
        ↺ 45°
      </button>
      <button 
        onClick={() => setRotation(0)}
        style={{...buttonStyle, background: '#ff4444'}}
      >
        Resetear
      </button>
    </div> */}

      <div style={{ height: "100%", width: "100vw", minHeight: "750px" }}>
        <MapContainer 
        center={[-33.4489, -70.6693]} 
        zoom={13} 
        style={{ height: "100%", width: "100%" }}
        >

        <TileLayer url={userConfig.selectedMapTyler.url} attribution={userConfig.selectedMapTyler.attributions} />

         <RotateControl angle={rotationAngle} />



        {/* 🔹 Renderizar los Waypoints */}
        {dataInfo.orders.map((order, index) => {
          if (!order.toAddressLocation || typeof order.toAddressLocation !== "object") return null;

          const lat = parseFloat(order.toAddressLocation._lat);
          const lon = parseFloat(order.toAddressLocation._long);
          if (isNaN(lat) || isNaN(lon)) return null;

          const color = order.intState ? "orange" :  order.index !== 999 ? colorByStatus[order.status]: "#999" || "#999";
          const position = [lat, lon];
          const route = order.driverLicensePlate || "Sin Ruta";
          const orderNumber = order.index !== 999 ? order.index : "!!";

          return (
            <Marker     
              key={index}
              position={position}
              icon={createSVGIcon(color)}
              eventHandlers={{
                click: () => {
                  if (userConfig.showItemsPopup) {
                   // setSelectedOrder(order);
                    setOrederIteractions(order)
                  }
                },
              }}>
              {!userConfig.showItemsPopup ? (
                  <Popup>
                    <Typography variant="caption" display="block">
                      <strong>Orden ID:</strong> {order.id} <br />
                      <strong>Ruta:</strong> {route} | Parada: {orderNumber}
                    </Typography>
                  </Popup>
                ): 
                   <Tooltip permanent direction="top" className="custom-tooltip"   style={{ 
                      background: "transparent", 
                      border: "none", 
                      boxShadow: "none", 
                      padding: 0 
                    }} >                    
                      <Typography variant="caption"  style={{
                        backgroundColor: color, // Fondo negro
                        color: "white", // Texto blanco
                        borderRadius: "50%", // Bordes redondeados
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        border: selectedOrder?.id === order.id ? "4px solid blue" : `2px solid ${color}`,
                        textAlign: "center",
                      }}>
                      <strong>{orderNumber}</strong>
                    </Typography>
                  </Tooltip>

                }
            </Marker>
          );
        })}

        {/* 🔹 Marcador del Conductor */}
       {userConfig.showDrivers && dataInfo.userData?.driverLocation &&
        !isNaN(dataInfo.userData.driverLocation.latitude) &&
        !isNaN(dataInfo.userData.driverLocation.longitude) && (
          <React.Fragment key={`driver-${dataInfo.userData.id}`}>
            <Marker
              position={[
                dataInfo.userData.driverLocation.latitude,
                dataInfo.userData.driverLocation.longitude,
              ]}
              icon={customIcon}
            >
              <Popup>
                <Typography variant="caption" display="block">
                  <Avatar src={dataInfo.userData.avatar || ""} />
                  <strong>Driver:</strong> {dataInfo.userData.name || "Sin nombre"}
                </Typography>
              </Popup>
            </Marker>

            {/* 🔵 Círculo de 100 metros alrededor del conductor */}
            <Circle
              center={[
                dataInfo.userData.driverLocation.latitude,
                dataInfo.userData.driverLocation.longitude,
              ]}
              radius={100}
              color={"#591e8f"}
              fillColor="rgba(0, 0, 255, 0.2)"
              fillOpacity={0.3}
              weight={2}
            />
          </React.Fragment>
        )}


        {Array.isArray(selectedViewLinesRider) && selectedViewLinesRider.length > 0 && (
          <>
            {userConfig.showRoutes && selectedViewLinesRider
              .filter((route) => routeGeometryRider.includes(route.id)) // 🔹 Filtrar rutas seleccionadas
              .map((route, index) => {
              // console.log("ROUTE : " ,route)
                const routeName = route.id || `Ruta_${index + 1}`;
                const color = route.routeColor || "blue"; // 🔹 Definir el color desde la ruta

                let geometryArray = [];

                if (!Array.isArray(route.geometry) || route.geometry.length === 0) {
                  console.warn(`⚠️ Geometría vacía o no definida en la ruta: ${route.id}`);
                  return null;
                }

                if (Array.isArray(route.geometry)) {
                  geometryArray = route.geometry
                    .map((point) => {
                      if (typeof point === "object" && "lat" in point && "lon" in point) {
                        return [parseFloat(point.lat), parseFloat(point.lon)];
                      }
                      if (Array.isArray(point) && point.length === 2) {
                        return [parseFloat(point[1]), parseFloat(point[0])];
                      }
                      return null;
                    })
                    .filter((coords) => coords !== null && !isNaN(coords[0]) && !isNaN(coords[1]));
                } else if (typeof route.geometry === "object" && !Array.isArray(route.geometry)) {
                  geometryArray = Object.values(route.geometry)
                    .filter((point) => Array.isArray(point) && point.length === 2)
                    .map(([lon, lat]) => [parseFloat(lat), parseFloat(lon)])
                    .filter((coords) => !isNaN(coords[0]) && !isNaN(coords[1]));
                }

                if (geometryArray.length === 0) return null; // Si no hay datos válidos, no renderizar

                return (
                  <Polyline
                    key={`${routeName}-${index}`}
                    positions={geometryArray} // ✅ Mantiene el formato correcto [[lat, lon], [lat, lon]]
                    color={color}
                    weight={4}
                    opacity={0.8}
                    lineCap="round"
                    stroke={true}
                    fillColor={color}
                    fillOpacity={1}
                  >
                    <Popup>
                      <Typography variant="caption" display="block">
                        <strong>Ruta:</strong> {routeName} <br />
                      </Typography>
                    </Popup>
                  </Polyline>
                );
              })}
          </>
        )}

        {Array.isArray(selectedViewLinesRider) && selectedViewLinesRider.length > 0 && (
          <>
            {userConfig.showRoutes && selectedViewLinesRider
              .filter((route) => routeGeometryRider.includes(route.id)) // 🔹 Filtrar rutas seleccionadas
              .flatMap((route) =>
                route.waypoints?.map((wp, index) => {
                  // 🔹 Extraer coordenadas con validación
                  const lat = parseFloat(wp.location?.lat);
                  const lon = parseFloat(wp.location?.lon || wp.location?.log);

                  if (isNaN(lat) || isNaN(lon)) return null; // 🚨 Evita coordenadas inválidas
                  const position = [lat, lon];
                  // 🔹 Obtener color según el estado de la parada
                  const fillColor = colorByStatus[wp.currentStatus] || "#34495e"; // Color por defecto si el estado no está definido

                  return (
                    <Marker key={index} position={position} icon={createSVGIcon(fillColor)}>
                      <Popup>
                        <Typography variant="caption" display="block">
                          <strong>Orden ID:</strong> {wp.id} <br />
                          <strong>Parada:</strong>  {wp.index}
                        </Typography>
                      </Popup>
                    </Marker>
                  );
                })
              )}
          </>
        )}


        {userConfig.showWaypoints && selectedViewRouteRiderData &&
        selectedViewRouteRiderData
        .filter((route) => selectedViewRouteRider.includes(route.id)) // 🔹 Filtrar solo rutas seleccionadas
        .flatMap((route) =>
          route.waypoints?.map((wp, index) => {
            const routeName = wp.routeName || "Sin Ruta";
            const orderNumber = wp.index || "#";

            // 🔹 Extraer coordenadas con validación
            const lat = parseFloat(wp.location?.lat);
            const lon = parseFloat(wp.location?.lon || wp.location?.log);

            if (isNaN(lat) || isNaN(lon)) {
              console.warn(`⚠️ Coordenadas inválidas para ${wp.id}:`, wp.location);
              return null;
            }

            // 🔹 Determinar el tipo de marcador
            if (wp.index === 0) {
              return (
                <Marker key={`home-${wp.id}`} position={[lat, lon]} icon={homeIcon}>
                  <Tooltip permanent direction="top">
                    🏡 Origen
                  </Tooltip>
                </Marker>
              );
            } else if (wp.index === route.waypoints.length - 1) {
              return (
                <Marker key={`dest-${wp.id}`} position={[lat, lon]} icon={customIcon}>
                  <Tooltip permanent direction="top">
                    🎯 Destino
                  </Tooltip>
                </Marker>
              );
            } else {
              return (
              <Marker     
                key={index}
                position={wp.location}
                icon={createSVGIcon(colorByStatus[wp.currentStatus])}
                eventHandlers={{
                  click: () => {
                    if (userConfig.showItemsPopup) {
                      // console.log(dataInfo.orders)
                      // console.log(wp)
                      // console.log(wp.id)
                      let order = dataInfo.orders.filter((item ) => item.id === wp.id )
                      // console.log(order)
                      if(order.length > 0){setSelectedOrder(order[0])}else{setSelectedOrder(wp)};
                    }
                  },
                }}>
              {!userConfig.showItemsPopup ? (
                  <Popup>
                    <Typography variant="caption" display="block">
                      <strong>Orden ID:</strong> {wp.id} <br />
                      <strong>Parada:</strong> {wp.includes} 
                    </Typography>
                  </Popup>
                ): 
                   <Tooltip permanent direction="top" className="custom-tooltip"   style={{ 
                      background: "transparent", 
                      border: "none", 
                      boxShadow: "none", 
                      padding: 0 
                    }} >                    
                      <Typography variant="caption"  style={{
                        backgroundColor: colorByStatus[wp.currentStatus], 
                        color: "white", 
                        borderRadius: "50%", 
                        width: "20px",
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "12px",
                        border: selectedOrder?.id === wp.id ? "4px solid blue" : `2px solid white`,
                        textAlign: "center",
                      }}>
                      <strong>{wp.index ? wp.index : "#"}</strong>
                    </Typography>
                  </Tooltip>

                }
            </Marker>

          
              );
            }
          })
        )}
        <MapHelper />

         <FlyToOrder order={orderIteration} />


      </MapContainer>
    </div>
    {userConfig.showItemsPopup && selectedOrder && (
       <ItemsPopup selectedOrder={selectedOrder} setSelectedOrder={setSelectedOrder} handleNavigate={handleNavigate}> </ItemsPopup>
      )}
    </Box>

  );
};

export default MapComponentMonitorStatusLeaflet;
