import React, { useState } from "react";
import { Box, Tabs, Tab, Typography, Divider } from "@mui/material";
import OrdersDailyView from "../../../../components/OrdersDailyView/OrdersDailyView";
import WeeklyOrders from "../../../../components/WeeklyOrders/WeeklyOrders";
import MonthlyOrders from "../../../../components/MonthlyOrders/MonthlyOrders";
import { useOutletContext } from "react-router-dom";

const HistorialViajes = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const { ordersHistory , groupsedByDay } = useOutletContext();





     const panesOrdenes = [
    {
      label: "Ordenes diarias",
      content:  <OrdersDailyView groupsedByDay={groupsedByDay} ></OrdersDailyView>,
    },
    {
      label: "Ordenes Semanales",
      // content: <OrdersWeeklyView />,
      content:<WeeklyOrders orders={ordersHistory.completadas} />,
    },
    {
      label: "Ordenes Mensuales",
      content: <MonthlyOrders orders={ordersHistory.completadas}/>,
    },
  ];

  // Pestañas de Reclamos (ejemplo)
  const panesReclamos = [
    {
      label: "Reclamos Pendientes",
      content: <Box>Listar Pendientes</Box>,
    },
    {
      label: "Casos cerrados",
      content: <Box>Listar cerrados</Box>,
    },
    {
      label: "Casos en cobro",
      content: <Box>Listar ordenes</Box>,
    },
  ];

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Historial de Viajes
      </Typography>
      <Tabs
        value={tabIndex}
        onChange={(e, newValue) => setTabIndex(newValue)}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Diarias" />
        <Tab label="Semanales" />
        <Tab label="Mensuales" />
      </Tabs>
      <Divider />
      <Box p={0.5}>
        {tabIndex === 0 && <Typography>{panesOrdenes[tabIndex].content}</Typography>}
        {tabIndex === 1 && <Typography>{panesOrdenes[tabIndex].content}</Typography>}
        {tabIndex === 2 && <Typography>{panesOrdenes[tabIndex].content}</Typography>}
      </Box>
    </Box>
  );
};

export default HistorialViajes;
