import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

// 🔹 Clave de encriptación (guárdala en .env si es posible)
const SECRET_KEY = "MI_CLAVE_SECRETA_123";

// 🔹 Función para encriptar datos
const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

// 🔹 Función para desencriptar datos
const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Error al desencriptar datos:", error);
    return null;
  }
};

// 🔹 Valores por defecto
const defaultConfig = {
  selectedMapTyler: {
    id: 4,
    name: "OpenStreetMap Light All",
    url: "https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png",
    attribution: "© OpenStreetMap contributors, Stadia Maps"
  },
  showItemsCant: false,
  showItemsPopup: false,
  showRoutes: true,
  showWaypoints: true,
  showDrivers: true,
};

// 🔹 Hook personalizado para manejar la configuración del usuario
const useUserConfig = () => {
  const [userConfig, setUserConfig] = useState(defaultConfig);
  const [isLoaded, setIsLoaded] = useState(false); // ⬅️ Control para asegurar que los datos se cargaron antes de renderizar

  useEffect(() => {
    const storedData = localStorage.getItem("userConfigBicciExpressRider");
    if (storedData) {
      const decryptedData = decryptData(storedData);
      if (decryptedData) {
        setUserConfig(decryptedData);
      }
    }
    setIsLoaded(true); // ⬅️ Indicar que ya cargamos la configuración
  }, []);

  useEffect(() => {
    if (isLoaded) {
      localStorage.setItem("userConfigBicciExpressRider", encryptData(userConfig));
    }
  }, [userConfig, isLoaded]);

  const updateUserConfig = (key, value) => {
    setUserConfig((prev) => ({ ...prev, [key]: value }));
  };

  return { userConfig, updateUserConfig, isLoaded };
};

export default useUserConfig;


