import React from "react";
import { Box, Card, CardContent, Avatar, Typography, Divider, CardActions, LinearProgress } from "@mui/material";
import QRCode from "react-qr-code";
import { useOutletContext } from "react-router-dom";

const CarnetRider = () => {

  const { userData } = useOutletContext();

  return userData ? (
    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
      <Card className="carnetRider">
        <CardContent>
          <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
            <Avatar src={userData.avatar} sx={{ height: 64, mb: 2, width: 64 }} />
            <strong>{userData.name}</strong>
            <strong>{userData.dni}</strong>
            <br />
            <div className="alignItemscenter">
              {userData.active === true ? (
                <span className="activo">Activo</span>
              ) : (
                <span className="inactivo">Inactivo"</span>
              )}
            </div>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <QRCode value={`https://get-bicci.web.app/consulta/${userData.id}`} size={150} level="H" fgColor="#741ab0" />
        </CardActions>
      </Card>
    </Box>
  ): <LinearProgress></LinearProgress>;
};

export default CarnetRider;
