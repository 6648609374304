import React, { useState } from 'react'
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Divider,
  } from '@mui/material';
import { useParams } from 'react-router-dom';

 var isFine ;
 var userUid = "noregister"
 var userState = false


const Consultaonlineid = ({expamadmin}) => {

  const navigate = useNavigate();
const { id } = useParams();

const [isFind, setIsFind] = React.useState('');
const [dataRider,setDatarider] = useState('')
const [rideruid , setRideruid] = useState(id)




React.useEffect(() => {
  if(id !== undefined){
      obtenerDatosdRider(id) //'LVY9zIL1OAbep6OvrrjEovLsKRD2' 
      expamadmin(true);
  }
},[id]);

  const handleNavigation = () => {
    navigate("/mi-cuenta"); // Ruta hacia la página "mi-cuenta"
  };

const obtenerDatosdRider = async (userUid) => {

 
  try {
        const userCollectionRef = collection(db, "drivers");
        const userDocRef = doc(userCollectionRef, userUid);
        const userSnapshot = await getDoc(userDocRef);
        var data = userSnapshot.data();
        //console.log(data)
        if(data !== undefined){
          setDatarider(data)
        
          setIsFind(true)}        
          
      } catch (error) {
          console.log(error)
          setIsFind(false)
      }
      } 

    

  
  return (
  <div id='RiderContent'>
    
    {
   isFind===true?(
    <div>
    <Card >
    <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
           <Card className="carnetRider" >
                 <CardContent>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                 <Avatar
                    src={dataRider.avatar}
                    sx={{
                      height: 64,
                      mb: 2,
                      width: 64
                    }} />

                      <strong>{dataRider.name}</strong>
                      <strong>{dataRider.dni}</strong>
                      <br></br>       
                      <div className='alignItemscenter'>{dataRider.active===true?(<span className="activo">Activo</span>):(<span className="inactivo">Inactivo</span>)} </div>
              </Box>
            </CardContent>
            <Divider />
            <div className="centercontent">
            <strong>ID: {dataRider.bicciNumber}</strong>
            </div>
            <CardActions > 
              <div className='centercontent'>
              <div className='borderCode'>
                      <QRCode value={`https://get-bicci.web.app/consulta/${dataRider.id}`} size={150} level='H' fgColor='#741ab0'/>
                      </div>
              </div>  
            </CardActions>
            <div className="centercontent contac-data">
            Empresa: Bicci Delivery
            <br></br>
            CONTACTO <br></br>
            +56 9 4595 0049
            <br></br>
            </div>
            </Card>
    </Box>
  </CardContent>
  <Divider />
  <CardActions>
     <Button variant="contained" color="primary" onClick={handleNavigation}>
        Ir a Mi Cuenta
      </Button>
  </CardActions>
   </Card>
</div>

   ):(<div>


    <Card >
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
                <Card className="carnetRiderNofont" >
                <Divider />
                <div className="centercontent">
                </div>
                <div className="centercontent contac-data-nofound">
                <strong>No se encuentra usuario</strong>
                <br></br>
                Numero Soporte <br></br>
                +56 9 4595 0049
                <br></br>
                </div>
                </Card>

        </Box>
      </CardContent>
      <Divider />
      <CardActions>
      <Button variant="contained" color="primary" onClick={handleNavigation}>
        Ir a Mi Cuenta
      </Button>
      </CardActions>

      </Card>
      
   </div>)

   } 


  </div>
  )
}

export default Consultaonlineid