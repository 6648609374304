import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Navbar from './components/Header/Navbar/Navbar';
import Footer from './components/Footer';
import Bicciriders from './Pages/Web/Bicciriders/Bicciriders';
import LoginEmail from './components/LoginEmail/LoginEmail';
import Rcontraseña from './components/LoginEmail/Rcontraseña';
import Page404 from './Pages/Page404';
import Consultaonlineid from './components/Consultaonlineid/Consultaonlineid';
import MiRoute from './Pages/Web/MiRoute/MiRoute';
import ScreenSizeProvider from './context/ScreenSizeProvider';
import UserProvider, { UserContext } from './context/UserProvider';
import { cerrarSesionAccion } from './redux/usuarioDucks';
import MicuentaRoutes from './Pages/Web/Micuenta/MicuentaRoutes';
import { Box } from '@mui/material';
import ListRoute from './Pages/Web/ListRoute/ListRoute';

function App() {
  return (
    <UserProvider>
      <ScreenSizeProvider>
        <AuthRoutes />
      </ScreenSizeProvider>
    </UserProvider>
  );
}

function AuthRoutes() {
  const { user } = useContext(UserContext); 
  const dispatch = useDispatch();
 // const [selectedSection, setSelectedSection] = useState(0)

  const [landScrenMenu , setLandScrenMenu ] = useState(true)
  const RutaPrivada = ({ children }) => {
    if (!user) {
      dispatch(cerrarSesionAccion());
      return <Navigate to="/login" />;
    }
    return children;
  };

  const expamadmin = () => {
   setLandScrenMenu(!landScrenMenu);
  };

  return (
    <Router>
      <Navbar />
      <Box sx={{
         paddingTop: "8vh !important",
         pb: "10% !important",
      }}>
        <Routes>
          {/* Rutas Públicas */}
          <Route path="/" element={<Bicciriders  />} />
          <Route path="/login" element={<LoginEmail />} />
          <Route path="/resset-pass" element={<Rcontraseña />} />
          <Route path="/consulta/:id" element={<Consultaonlineid expamadmin={expamadmin} />} />

          {/* Rutas Privadas */}
          <Route path="/mi-cuenta/*" element={<RutaPrivada><MicuentaRoutes /></RutaPrivada>} />

          <Route path="/lista" element={<RutaPrivada><ListRoute /></RutaPrivada>} />

          <Route path="/route" element={<RutaPrivada><MiRoute /></RutaPrivada>} />
          

          {/* Ruta para 404 */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Box>

      {/* ✅ Footer se ajusta dependiendo del tamaño de pantalla */}
      <Footer />
    </Router>
  );
}

export default App;
