  import * as XLSX from "xlsx";
  import Moment from "moment";
  export const exporExcelFileCircuit = (data) => {

    

    const matrizReporte = [
      [
        "Unit/Flat/Unit Name",
        "Address Line 1",
        "Address Line 2",
        "City",
        "State Zip",
        "Country",
        "Earliest Arrival Time",
        "Latest Arrival Time",
        "Notes",
        "Size",
        "Recipient Name",
        "Type of stop",
        "Recipient Email Address",
        "Recipient Phone Number",
        "Id",
        "Package Count",
        "Products",
        "Seller website",
        "Seller Name",
        "Driver (email or phone number)",
      ],
      ...data.map((dato) => [
        "",
        dato.toAddress,
        dato.commune2,
        dato.commune1 ? dato.commune1 : dato.comuna,
        "",
        dato.region,
        "CL",
        "",
        "",
        dato.address ? dato.address : dato.formatted_add,
        dato.size,
        dato.customer_name ? dato.customer_name : dato.userName,
        "",
        "",
        `${dato.customer_phone ? dato.customer_phone : dato.userPhone}`,
        dato.id,
        dato.quantity,
        "",
      ]),
    ];

    const libro = XLSX.utils.book_new();
    const hoja = XLSX.utils.aoa_to_sheet(matrizReporte);
    XLSX.utils.book_append_sheet(libro, hoja, "DATA");

    XLSX.writeFile(
      libro,
      `${data[0].driverName} Circuit ${Moment(Date.now()).format(
        "YYYY-MM-DD"
      )}.xlsx`
    );
  };
