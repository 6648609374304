import { firebase, db} from '../firebase'
import { collection, doc, getDoc } from "firebase/firestore";
import { signInWithEmailAndPassword ,  getAuth, GoogleAuthProvider, signInWithPopup} from "firebase/auth";

const auth = getAuth();

// data inicial
const dataInicial = {
    loading: false,
    activo: false
}

// types
const LOADING = 'LOADING'
const USUARIO_ERROR = 'USUARIO_ERROR'
const USUARIO_EXITO = 'USUARIO_EXITO'
const CERRAR_SESION = 'CERRAR_SESION'
const USUARIO_ROL = 'USUARIO_ROL'
const USUARIO_ERROR_ROL = 'USUARIO_ERROR_ROL'
const USUARIO_ERROR_LOGIN_EMAIL='USUARIO_ERROR_LOGIN_EMAIL'
// reducer
export default function usuarioReducer (state = dataInicial, action) {
    switch(action.type){
        case LOADING:
            return {...state, loading: true}
        case USUARIO_ERROR:
            return {...dataInicial}
        case USUARIO_EXITO:
            return {...state, loading: false, user: action.payload, activo: true}
        case CERRAR_SESION:
            return {...dataInicial}
        case USUARIO_ERROR_LOGIN_EMAIL:
                return {...state, error: action.error}
        default:
            return {...state}
    }
}

export const ingresoUsuarioAccion = () => async (dispatch) => {
  dispatch({ type: 'LOADING' });
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;

    // Opcional: Guardar información adicional del usuario en Firestore
    // const userRef = firestore.collection('users').doc(user.uid);
    // await userRef.set({
    //   email: user.email,
    //   // Otros datos que desees almacenar
    // }, { merge: true });

    dispatch({
      type: 'USUARIO_EXITO',
      payload: {
        uid: user.uid,
        email: user.email,
        // Otros datos que desees almacenar en el estado
      },
    });

    // Guardar en localStorage si es necesario
    localStorage.setItem('userRider', JSON.stringify({
      uid: user.uid,
      email: user.email,
    }));
  } catch (error) {
    console.error("Error en el inicio de sesión con Google:", error);
    dispatch({ type: 'USUARIO_ERROR', error });
  }
};

export const leerUsuarioActivoAccion = () => (dispatch) => {
    if(localStorage.getItem('userRider')){
        dispatch({
            type: USUARIO_EXITO,
            payload: JSON.parse(localStorage.getItem('userRider'))
        })
    }
}

export const cerrarSesionAccion = () => (dispatch) => {
    auth.signOut()
    localStorage.removeItem('userRider')
    dispatch({
        type: CERRAR_SESION
    })
}

//FormLogig
export const ingresoUsuarioLogin = (email, pass) => async(dispatch) => {
 
    dispatch({
        type: LOADING
    })
    try {
        const res = await signInWithEmailAndPassword(auth, email, pass)
        
      let userCollectionRef = collection(db, "drivers");
      let userDocRef = doc(userCollectionRef, res.user.uid);
      let userSnapshot = await getDoc(userDocRef);


       if (userSnapshot.exists()) {
        
     
        const data = userSnapshot.data();
       
    
        dispatch({
                    type: USUARIO_EXITO,
                    payload: {
                        uid: res.user.uid,
                        email: res.user.email,
                        roll:'driver',
                        error:null,
                    }
                })
        localStorage.setItem('userRider', JSON.stringify({
            uid: res.user.uid,
            email: res.user.email,
            roll:'driver',
           
        }))
        dispatch(verificadmin(res.user.uid))

        } else {
    
        dispatch({
                type: USUARIO_ERROR_LOGIN_EMAIL,
                error:'Usuario o contraseña incorrectas'
            })

        }

        } catch (error) {
     
            dispatch({
                type: USUARIO_ERROR_LOGIN_EMAIL,
                error:'Usuario o contraseña incorrectas'
            })
        }
}

//VALIDAION USUARIO BICCI 
export const verificadmin = (uid) => async(dispatch) => {
  //  console.log('verificando...');
    try {
        const isAdmin = await db.collection('administrators')
        .doc(uid)
        .get();
        var dataAdmin = isAdmin.data()
    //    console.log(dataAdmin)

         dispatch({
             type: USUARIO_EXITO,
             payload: {
                 uid: uid,
                 email: dataAdmin.email,
                 roll:dataAdmin.role,
                 error:null,
             }
         })

         localStorage.setItem('userRider', JSON.stringify({
             uid: uid,
             email: dataAdmin.email,
             roll:dataAdmin.role,
         }))

    } catch (error) {
         
     
    }
}

// CPACIDADES DE USUARIO
export const rolldeUsuario = (rollId) => async(dispatch) => {
    dispatch({
        type: LOADING
    })
    try {
        
        const userRoll = await db.collection('roles')
        .doc(rollId)
        .get();
        dispatch({
            type: USUARIO_ROL,
            permisos:userRoll.data(),
        })
        localStorage.setItem('ebiexservices', JSON.stringify({permisos:userRoll.data()}))
        
        
        } catch (error) {
            //console.log(error.code)
            dispatch({
                type: USUARIO_ERROR_ROL,
                error:'Funciones de usuario No definidas 404',
            })
        }
}