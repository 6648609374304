import React from "react";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { translateStatesBicciFilter, translateStatesfalabbela } from "../../../../Utils/translateStates";
import { useOutletContext } from "react-router-dom";
import { exporExcelFileCircuit } from "../../../../Utils/exporExcelFileCircuit";

const ViajesActivos = () => {
    const { ordersHistory } = useOutletContext();

  if (!ordersHistory?.pendientes?.length) {
    return <Typography>No hay viajes pendientes.</Typography>;
  }

    // -----------------------------------------------------------------------------------
  // 6. Vistas (contenido) para cada pestaña (diaria, semanal, mensual)
  // -----------------------------------------------------------------------------------
  // A) Componente auxiliar para listar órdenes en Cards


  const OrdersList = ({ orders }) => {
    return (
      <Grid container spacing={2}>
        {orders.map((order) => {
          // Intentamos formatear la fecha
          const dateToShow = order.updatedAt ?? order.createdAt;
          const dateFormatted = moment(
            dateToShow?.seconds ? dateToShow.seconds * 1000 : dateToShow
          ).format("DD/MM/YYYY HH:mm");

          return (
            <Grid item xs={12} key={order.id}>
              <Card>
                <CardContent>

                  <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <Box>
                  <Typography variant="subtitle1" fontWeight="bold">
                    ID: {order.id}
                  </Typography>
                  <Typography variant="body2">
                    Fecha: {dateFormatted}
                  </Typography>
                  <Typography variant="body2">
                    Destino: {order.toAddress ?? "No especificado"}
                  </Typography>
                  <Typography variant="body2">
                    Estado: { translateStatesBicciFilter[order.status] }
                  </Typography>
                  </Box>
                  <Box>
                  <Typography variant="body2">
                   {translateStatesfalabbela[order.intState]}
                  </Typography>
                    <Typography variant="body2">
                    Observación: {order.observationAdmin}
                  </Typography>
                  </Box>
                  </Box>
                 
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  };


  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Pendientes por entrega o devolución hoy: {ordersHistory.pendientes.length}
      </Typography>
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          color="primary"
          onClick={() => exporExcelFileCircuit(ordersHistory.pendientes)}
        >
          Descargar Circuit
        </Button>
      </Box>
      <Box mt={2}>
        <OrdersList orders={ordersHistory.pendientes} />
      </Box>
    </Box>
  );
};

export default ViajesActivos;
