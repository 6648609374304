import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  LinearProgress
} from "@mui/material";
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";
import moment from "moment";
import { ScreenSizeContext } from "../../../context/ScreenSizeProvider";

const Micuenta = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userUid = useSelector((store) => store.usuario?.user?.uid);
  const { movilActive } = useContext(ScreenSizeContext);

  const [userData, setUserData] = useState({});
  const [ordersHistory, setOrdersHistory] = useState({});
  const [groupsedByDay , setGroupsedByDay] = useState({});
  const [ordersRider, setOrdersRiders] = useState([]);
  
  useEffect(() => {
    if (userUid) {
      fetchUserData(userUid);
      getOrders(userUid);
    }
  }, [userUid]);

   useEffect(() => {
    if (ordersRider.length === 0) return;

    // Filtrar órdenes según su estado (ajusta la lógica de acuerdo a tu negocio)
    const pendientes = ordersRider.filter(order => order.status > 2 && order.status < 8);
    const canceladas = ordersRider.filter(order => order.status < 0);
    const creadas = ordersRider.filter(order => order.status === 0);
    const confirmadas = ordersRider.filter(order => order.status === 1);
    const completadas = ordersRider.filter(order => order.status === 8);
    const reclamos = ordersRider.filter(order => order.reclaind === true);

    // Guardar histórial (opcional, para otras visualizaciones)
    const historialeestados = {
      pendientes,
      canceladas,
      completadas,
      creadas,
      confirmadas,
      reclamos
    };

    setOrdersHistory(historialeestados);

    // Calcular agrupaciones
    calculateCoutes(completadas);
  }, [ordersRider]);

  const fetchUserData = async (uid) => {
    try {
      const userDocRef = doc(collection(db, "drivers"), uid);
      const userSnapshot = await getDoc(userDocRef);

      if (userSnapshot.exists()) {
        const data = userSnapshot.data();
        setUserData({
          ...data,
          createdAt: data?.createdAt?.seconds
            ? moment.unix(data.createdAt.seconds).format("DD/MM/YYYY")
            : "No especificado"
        });
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };



  const getOrders = async (uid) => {
   try {
      const collRef = collection(db, "orders");
      const q = query(collRef, where("driverID", "==", uid));
      const requestUser = await getDocs(q);
      const requestItems = requestUser.docs.map((doc) => ({
        id: doc.id, // ID del documento en Firestore
        ...doc.data()
      }));
      setOrdersRiders(requestItems);
    } catch (e) {
      console.error("Error al recibir documentos de cliente ", e);
    }
  };


  

   const calculateCoutes = async (completadas) => {
    // A) Conversión de Timestamps (si vienen de Firestore)
    function convertFirestoreTimestampToDate(timestamp) {
      if (!timestamp) return null;
      if (timestamp.seconds !== undefined && timestamp.nanoseconds !== undefined) {
        return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6);
      }
      const date = new Date(timestamp);
      return isNaN(date.getTime()) ? null : date;
    }

    // // B) Agrupar por día
     function groupByDay(orders) {
       return orders.reduce((groups, order) => {
         const dateObj = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
         if (!dateObj) {
           console.warn("Invalid date for order:", order);
           return groups;
         }
         const dayKey = moment(dateObj).format("YYYY-MM-DD");
         if (!groups[dayKey]) {
           groups[dayKey] = [];
         }
         groups[dayKey].push(order);
         return groups;
       }, {});
     }

    // // C) Agrupar por semana
    // function getWeekOfYear(date) {
    //   if (!(date instanceof Date) || isNaN(date.getTime())) return null;
    //   const start = new Date(date.getFullYear(), 0, 1);
    //   const diff = date - start + (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
    //   return Math.floor(diff / (7 * 24 * 60 * 60 * 1000)) + 1;
    // }

    // function groupByWeek(orders) {
    //   return orders.reduce((groups, order) => {
    //     const date = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
    //     if (!date) {
    //       console.warn('Invalid date for order:', order);
    //       return groups;
    //     }
    //     const week = getWeekOfYear(date);
    //     if (!week) {
    //       console.warn('Could not determine the week for order:', order);
    //       return groups;
    //     }
    //     if (!groups[week]) {
    //       groups[week] = [];
    //     }
    //     groups[week].push(order);
    //     return groups;
    //   }, {});
    // }

    // D) Agrupar por mes
    // function groupByMonth(orders) {
    //   console.log(orders.length)
    //   return orders.reduce((groups, order) => {
    //     const date = convertFirestoreTimestampToDate(order.updatedAt ?? order.createdAt);
    //     if (!date) {
    //       console.warn('Invalid date for order:', order);
    //       return groups;
    //     }
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const key = ${year}-${month};
    //     if (!groups[key]) {
    //       groups[key] = [];
    //     }
    //     groups[key].push(order);
    //     return groups;
    //   }, {});
    // }

    // E) Llamamos a las agrupaciones
    const dayGrouped = groupByDay(completadas);
    // const weekGrouped = groupByWeek(completadas);
    // const monthGrouped = groupByMonth(completadas);

    setGroupsedByDay(dayGrouped);
    // setGroupsedByWeek(weekGrouped);
    // setGroupsedByMonth(monthGrouped);
  };

  // Rutas para la navegación en el menú lateral
  const sections = [
    { label: "Viajes Activos", path: "viajes-activos" },
    { label: "Mapa Viajes Activos", path: "mapa-viajes-activos" },
    { label: "Buscar Rutas", path: "buscar-rutas" },
    { label: "Historial de Viajes", path: "historial-viajes" },
    { label: "Detalles de Cuenta", path: "detalles-cuenta" },
    { label: "Desempeño", path: "desempeno" },
    { label: "Carnet Rider", path: "carnet-rider" },
  ];



  return userData && ordersHistory ?(

    <Box sx={{ padding: 3 }}>
      <Grid container spacing={3}>
        {/* Menú lateral */}
        <Grid item xs={12} md={4}>
          <Card>
            <CardContent>
              <Box alignItems="center" textAlign="center">
                <Avatar src={userData.avatar} sx={{ height: 100, width: 100, margin: "auto" }} />
                <Typography typography={{ fontSize: "1em" }}>
                  {userData.name || "No especificado"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {userData.email || "No especificado"}
                </Typography>
              </Box>
            </CardContent>
            <Divider />
            <CardActions>
              <Typography variant="body2" textAlign="center" sx={{ width: "100%" }}>
                {userData.active ? "Activo" : "Inactivo"}
              </Typography>
            </CardActions>
          </Card>

          {!movilActive && (
            <Grid item xs={12} sx={{ marginTop: 2 }}>
              <List>
                {sections.map((section, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      selected={location.pathname.includes(section.path)}
                      onClick={() => navigate(`/mi-cuenta/${section.path}`, { state: { userData, ordersHistory } })}
                    >
                      <ListItemText primary={section.label} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
          )}
        </Grid>

        {/* Contenido principal (Outlet con Props) */}
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Outlet context={{ userData, ordersHistory , groupsedByDay}} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ): <LinearProgress></LinearProgress>;
};

export default Micuenta;
