import React, { useState } from "react";
import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const ControlPanel = ({
  showRoutes,
  setShowRoutes,
  showWaypoints,
  setShowWaypoints,
  showDrivers,
  setShowDrivers,
  selectedViewRouteRider,
  handleRouteSelection,
  setSelectedViewRouteRider,
  routeGeometryRider,
  visibleControl = false,
  handleToggleVisibility,
}) => {



  return (
    <>
      {/* 🔹 Botón Flotante para Mostrar/Ocultar Panel */}
      <IconButton
        sx={{
          position: "fixed",
          top: "85px",
         // bottom: visibleControl ? "270px" : "85px", // 🔹 Se mueve arriba cuando está visible
          right: "8%",
          transform: "translateX(-50%)",
          zIndex: 1100,
          background: "white",
          boxShadow: 3,
          borderRadius: "50%",
          width: "30px",
          height: "30px",
        }}
        onClick={() => handleToggleVisibility("control")}
      >
        {visibleControl ? <VisibilityOff /> : <Visibility />}
      </IconButton>
      {/* {visibleControl ?
       <IconButton
        sx={{
          position: "fixed",
          bottom: visibleControl ? "210px" : "15px", // 🔹 Se mueve arriba cuando está visible
          right: "1%",
          transform: "translateX(-50%)",
          zIndex: 1100,
          background: "white",
          boxShadow: 3,
          borderRadius: "50%",
          width: "50px",
          height: "50px",
        }}
        onClick={() => navigate("/mi-cuenta")}
      >
         <HomeIcon /> 
      </IconButton>
      :null} */}

      {/* 🔹 Panel de Controles */}
      {visibleControl && (
        <Box
          sx={{
              position: "fixed",
              bottom: 60,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
              background: "white",
              padding: { xs: "5px", sm: "10px", md: "15px" },
              borderRadius: "12px 12px 0 0",
              boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
              width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" }, // 🔹 Ajustar el ancho
              maxWidth: "400px", // 🔹 Máximo de 400px en cualquier caso
              minWidth: "250px", // 🔹 No se reducirá demasiado en pantallas pequeñas
              textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{
              fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
              textAlign: "center",
            }}
          >
            Controles de Vista
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={showRoutes}
                  onChange={() => setShowRoutes(!showRoutes)}
                />
              }
              label="Mostrar Rutas"
              sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showWaypoints}
                  onChange={() => setShowWaypoints(!showWaypoints)}
                />
              }
              label="Mostrar Paradas"
              sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={showDrivers}
                  onChange={() => setShowDrivers(!showDrivers)}
                />
              }
              label="Mostrar Conductor"
              sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
            />
          </FormGroup>

          {/* 🔹 Selector de Rutas */}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" },
              }}
            >
              Seleccionar Ruta
            </InputLabel>
            <Select
              multiple
              value={selectedViewRouteRider}
              onChange={(e) => setSelectedViewRouteRider(e.target.value)}
              sx={{
                fontSize: { xs: "0.8rem", sm: "1rem" },
              }}
            >
              {routeGeometryRider.map((routeId, index) => (
                <MenuItem
                  key={routeId}
                  value={routeId}
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  {routeId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
    </>
  );
};

export default ControlPanel;
