import React from "react";
import { Typography } from "@mui/material";

const Desempeno = () => {
  return (
    <Typography variant="body1" align="center">
      No se muestran datos
    </Typography>
  );
};

export default Desempeno;
