import React, { useEffect, useState, useCallback, useRef } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { Box, LinearProgress, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const mapContainerStyle = {
  width: "100%",
  height: "50vh",
};

const defaultCenter = {
  lat: -33.4489, // Santiago de Chile (latitud)
  lng: -70.6693, // Santiago de Chile (longitud)
};

const libraries = ["places"];

const MapaOrdersPendientesView = ({ orders, setOrderData }) => {

  const navigate = useNavigate();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_APIGOOGLEMAPS, // Clave API válida
    libraries,

  });

  const mapRef = useRef(null);
  const [markersPending, setMarkersPending] = useState(null);
  const [viewMarkert, setViewMarkert] = useState(false);

  useEffect(() => {},[viewMarkert])

    useEffect(() => {
      if (orders && orders.length > 0) {
        setMarkersPending(orders);
      }
    }, [orders]);

    useEffect(() => {
      if (mapRef.current && markersPending.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        markersPending.forEach((order) => {
          const lat = parseFloat(order.toAddressLocation._lat);
          const lng = parseFloat(order.toAddressLocation._long);
          if (
            !isNaN(lat) &&
            !isNaN(lng) &&
            lat >= -90 &&
            lat <= 90 &&
            lng >= -180 &&
            lng <= 180
          ) {
            bounds.extend({ lat, lng });
          } else {
            console.warn(`Coordenadas inválidas para la orden ${order.id}: lat=${lat}, lng=${lng}`);
          }
        });
        mapRef.current.fitBounds(bounds);
      }
    }, [markersPending]);

    const onMapLoad = useCallback((map) => {
      mapRef.current = map;
    }, []);

  const handleMarkerClick = (order) => {
    setOrderData(order);
  };

  if (loadError) return <div>Error al cargar Google Maps</div>;
  if (!isLoaded) return <div>Cargando...</div>;

  return markersPending ? (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Viajes Asignados
      </Typography>
      <Box sx={{ justifyContent: "space-between", display: "flex"}}>
      <Button onClick={()=>  setViewMarkert(!viewMarkert) } variant="outlined" sx={{fontSize: "0.8em" , p:0.5 , m:0.5 , width: "48%"}}> Paradas</Button>
      <Button onClick={()=>  navigate(`/route`) } variant="outlined" sx={{fontSize: "0.8em", p:0.5 , m:0.5 , width: "48%"}}> Ver ruta</Button>
      </Box>
      <Box
        sx={{
          mt: 2,
          width: "100%",
          height: "50vh",
        }}
      >
        {viewMarkert ?   
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={defaultCenter}
          zoom={12}
          onLoad={onMapLoad}>

          </GoogleMap> : 
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={defaultCenter}
          zoom={12}
          onLoad={onMapLoad}>


          {markersPending.length > 0 ?  
            markersPending.map((order) => (
              <Marker
                key={order.id}
                position={{
                  lat: parseFloat(order.toAddressLocation._lat),
                  lng: parseFloat(order.toAddressLocation._long),
                }}
                onClick={() => handleMarkerClick(order)}
              />
            )):<></>} 
        </GoogleMap>
         }
      </Box>
    </Box>
  ):<LinearProgress />;
};

export default MapaOrdersPendientesView;
