import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ingresoUsuarioAccion } from '../../redux/usuarioDucks';
import { Box, Button, Typography, Avatar } from '@mui/material';

const BtnAceceder = () => {
  const loading = useSelector((store) => store.usuario.loading);
  const dispatch = useDispatch();

  return (
    <Box>
      {/* Botón de acceso rápido (comentado en el original) */}
      {/* 
      <Button
        variant="contained"
        color="primary"
        onClick={() => dispatch(ingresoUsuarioAccion())}
        disabled={loading}
        startIcon={
          <Avatar
            src="https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"
            alt="Google Logo"
            sx={{ width: 24, height: 24 }}
          />
        }
        sx={{ mb: 2 }}
      >
        Acceso rápido con Google
      </Button>
      */}

      {/* Enlace a la app Bicci Rider */}
      <Typography variant="body1" sx={{ mb: 2 }}>
        <a
          href="https://play.google.com/store/apps/details?id=cool.mavericks.biccirider"
          style={{ textDecoration: 'none', color: '#2962ff' }}
        >
          Puedes crear cuenta rider descargando nuestra app Bicci Rider.
        </a>
      </Typography>

      {/* Enlaces a las tiendas de aplicaciones */}
      <Box
        className="brandsContent"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
          width: "100%",
          height: "auto"
        }}
      >
        <a href="https://play.google.com/store/apps/details?id=cool.mavericks.biccirider">
          <Avatar
            src="https://bicci.cl/wp-content/uploads/2020/07/GooglePlay-y-AppStore-2.png"
            alt="Google Play Store"
            sx={{     
              width: "150PX",
              height: "auto", 
              borderRadius: "3px"}}
           
          />
        </a>
        {/* <a href="https://apps.apple.com/cl/app/bicci-delivery/id1526222925">
          <Avatar
            src="https://bicci.cl/wp-content/uploads/2020/07/GooglePlay-y-AppStore-1.png"
            alt="App Store"
            sx={{ width: 100, height: 40 }}
          />
        </a> */}
      </Box>
    </Box>
  );
};

export default BtnAceceder;
