


    import React, { useState } from "react";
    import {
      Box,
      Typography,
      FormGroup,
      FormControlLabel,
      Switch,
      FormControl,
      InputLabel,
      Select,
      MenuItem,
      IconButton,
    } from "@mui/material";
   import SettingsIcon from '@mui/icons-material/Settings';
   
    const ConfigPanel = ({
      showItemsPopup = false, 
      setShowItemsPopup,
      showItemsCant = false,
      setShowItemsCant, 
      selectedMapTyler  = "",
      setSelectedMapTyler,
      mapsTylers = [],
      visibleConfig = false,
      handleToggleVisibility,
    }) => {

    const handleChange = (event) => {
    const selectedMap = mapsTylers.find((tyler) => tyler.name === event.target.value);
        setSelectedMapTyler(selectedMap);
    };
 
    
      return (
        <>
          {/* 🔹 Botón Flotante para Mostrar/Ocultar Panel */}
          <IconButton
            sx={{
              position: "fixed",
              top: "85px",
             // bottom: visibleConfig ? "270px" : "85px", // 🔹 Se mueve arriba cuando está visible
              right: "18%",
              transform: "translateX(-50%)",
              zIndex: 1100,
              background: "white",
              boxShadow: 3,
              borderRadius: "50%",
              width: "30px",
              height: "30px",
            }}
            onClick={() => handleToggleVisibility("config")}
          >
            {visibleConfig ? <SettingsIcon /> : <SettingsIcon />}
          </IconButton>
          {/* {visibleConfig ?
           <IconButton
            sx={{
              position: "fixed",
              bottom: visibleConfig ? "210px" : "15px", // 🔹 Se mueve arriba cuando está visible
              right: "1%",
              transform: "translateX(-50%)",
              zIndex: 1100,
              background: "white",
              boxShadow: 3,
              borderRadius: "50%",
              width: "50px",
              height: "50px",
            }}
            onClick={() => navigate("/mi-cuenta")}
          >
             <HomeIcon /> 
          </IconButton>
          :null} */}
    
          {/* 🔹 Panel de Controles */}
          {visibleConfig && (
            <Box
              sx={{
                position: "fixed",
                bottom: 60,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
                background: "white",
                padding: { xs: "5px", sm: "10px", md: "15px" },
                borderRadius: "12px 12px 0 0",
                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                width: { xs: "100%", sm: "100%", md: "60%", lg: "40%" },
                maxWidth: "400px",
                textAlign: "center",
              }}
            >
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  fontSize: { xs: "1rem", sm: "1.2rem", md: "1.4rem" },
                  textAlign: "center",
                }}
              >
                Configuración
              </Typography>
    
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showItemsCant}
                      onChange={() => setShowItemsCant(!showItemsCant)}
                    />
                  }
                  label="Mostrar cantidades de paquetes"
                  sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={showItemsPopup}
                      onChange={() => setShowItemsPopup(!showItemsPopup)}
                    />
                  }
                  label="Mostrar Información extra al seleccionar"
                  sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                />
              </FormGroup>
    
              {/* 🔹 Selector de Rutas */}
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel
                  sx={{
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  }}
                >
                  Tipo de Mapa
                </InputLabel>
                  <Select
                    value={selectedMapTyler.name}
                    onChange={handleChange}
                    sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                    >
                    {mapsTylers.map((tyler) => (
                        <MenuItem
                        key={tyler.id}
                        value={tyler.name}
                        sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                        >
                        {tyler.name}
                        </MenuItem>
                    ))}
                    </Select>
              </FormControl>
            </Box>
          )}
        </>
      );
    };
    
    export default ConfigPanel;
    