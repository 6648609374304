import React, { useContext, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Menu, MenuItem, Paper } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import ListIcon from '@mui/icons-material/List';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';
import RouteIcon from '@mui/icons-material/Route';

const MobileFooter = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [menuAnchor, setMenuAnchor] = useState(null);

  // Abre el menú al hacer clic en "Menú"
  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  // Cierra el menú
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  // Manejar la navegación desde el menú
  const handleMenuNavigate = (path) => {
    navigate(path);
    handleMenuClose(); // Cerrar el menú después de navegar
  };

    const textColorMewnu = {
        color: "#fff", // Cambia el color del texto
        '& .MuiBottomNavigationAction-label': {
        color: "#fff" // Asegura que el texto del label sea blanco
        },
        '& .MuiSvgIcon-root': {
        color: "#fff" // Cambia el color del icono a blanco
        }
   }

     const getCurrentIndex = () => {
    if (location.pathname.includes("mapa-viajes-activos")) return 1;
    if (location.pathname.includes("buscar-rutas")) return 2;
    if (location.pathname.includes("historial-viajes")) return 3;
    if (location.pathname.includes("detalles-cuenta")) return 4;
    return 0;
  };





  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9000 , backgroundColor: '#741ab0'}} elevation={3}>
        <BottomNavigation
        showLabels
        value={getCurrentIndex()}
        onChange={(_, newValue) => {
          const paths = [
            "/mi-cuenta/viajes-activos",
            "/mi-cuenta/mapa-viajes-activos",
            "/mi-cuenta/buscar-rutas",
            "/mi-cuenta/historial-viajes",
            "/mi-cuenta/detalles-cuenta",
          ];
          navigate(paths[newValue]);
        }}
        sx={{ backgroundColor: "#741ab0", color: "#fff" }}
      >
         {user ? (<BottomNavigationAction  sx={textColorMewnu} label="Inicio" icon={<HomeIcon  />} onClick={() => navigate('/')} />):null}

         {user ? (
            <BottomNavigationAction
              sx={textColorMewnu}
              label="Menú"
              icon={<AccountCircleIcon />}
              onClick={handleMenuOpen} // Abre el menú
            />
          ) : (
        <BottomNavigationAction 
         sx={textColorMewnu} 
         label="Login" 
         icon={<LoginIcon />} 
         onClick={() => 
         navigate('/login')} />
        )}
        
        {user ?   
        <BottomNavigationAction 
        sx={textColorMewnu} 
        label="Lista" 
        icon={<ListIcon />} 
        onClick={() => 
        navigate('/lista')} 
        /> : 
        null}

        {user ?   
        <BottomNavigationAction sx={textColorMewnu} label="Rutas" icon={<RouteIcon />} onClick={() => navigate('/route')} /> : 
        null}
      </BottomNavigation>
           {/* Menú emergente en la parte inferior */}
      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { width: '100%', bottom: 56, left: 0, position: 'fixed', backgroundColor: '#fff' }
        }}
      >
        <MenuItem onClick={() => handleMenuNavigate('/mi-cuenta/viajes-activos')}>Viajes Activos</MenuItem>
        <MenuItem onClick={() => handleMenuNavigate('/mi-cuenta/mapa-viajes-activos')}>Mapa Viajes Activos</MenuItem>
        <MenuItem onClick={() => handleMenuNavigate('/mi-cuenta/buscar-rutas')}>Buscar Rutas</MenuItem>
        <MenuItem onClick={() => handleMenuNavigate('/mi-cuenta/historial-viajes')}>Historial de Viajes</MenuItem>
        <MenuItem onClick={() => handleMenuNavigate('/mi-cuenta/detalles-cuenta')}>Detalles de Cuenta</MenuItem>
      </Menu>
    </Paper>
  );
};

export default MobileFooter;
