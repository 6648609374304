import React from 'react';
import { createRoot } from "react-dom/client";
import './scss/global.scss';
import App from './App.jsx';

import { Provider } from 'react-redux';
import generateStore from './redux/store';

const store = generateStore();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);


  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );

