import React from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { translateStatesBicciFilter } from '../../../Utils/translateStates';

const ItemsPopup = ({selectedOrder , setSelectedOrder , handleNavigate}) => {

 // 🔹 Colores según el estado de la orden
  const colorByStatus = {
    "-1": "#808080",
    0: "#6993a3",
    1: "#8dcfe9",
    2: "#1aa7df",
    3: "#7f8fe3",
    4: "#687de9",
    5: "#455edf",
    6: "#1231d5",
    7: "#63d77d",
    8: "#08a12b",
    9: "#08a12b",
  };
  return (
    <Box
          sx={{
            position: "fixed",
            bottom: 60,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            background: "white",
            padding: { xs: "5px", sm: "10px", md: "15px" },
            borderRadius: "12px 12px 0 0",
            boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
            width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" }, // 🔹 Ajustar el ancho
            maxWidth: "400px", // 🔹 Máximo de 400px en cualquier caso
            minWidth: "250px", // 🔹 No se reducirá demasiado en pantallas pequeñas
            textAlign: "center",
          }}
        >
           <IconButton
            sx={{
              position: "fixed",
              bottom: "80%", // 🔹 Se mueve arriba cuando está visible
              right: "0%",
              transform: "translateX(-50%)",
              zIndex: 1100,
              background: "white",
              boxShadow: 3,
              borderRadius: "50%",
              width: "25px",
              height: "25px",
            }}
            onClick={() => setSelectedOrder(null)}
          >
            {<HighlightOffIcon sx={{color: "red"}}/>}
          </IconButton>

           

          <Box sx={{textAlign: "left" , pl: 0}}>
          <Box sx={{ display: "flex", alignItems: "center", p: "2px", position: "relative" }}>
            {/* Contenedor para los botones */}
            <Box sx={{ display: "flex", alignItems: "center", position: "absolute", left: 0 }}>
              {selectedOrder.toAddressLocation ? <>
              <a
                href={`https://waze.com/ul?ll=${selectedOrder.toAddressLocation.latitude},${selectedOrder.toAddressLocation.longitude}&navigate=yes`}
                target="_blank"
                rel="noopener noreferrer"
                alt="Waze"
              >
                <img src="assets/waze.png" alt="Waze" style={{ maxWidth: "32px", marginRight: "2px" }} />
              </a>
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${selectedOrder.toAddressLocation.latitude},${selectedOrder.toAddressLocation.longitude}`}
                target="_blank"
                rel="noopener noreferrer"
                alt="Google Maps"
              >
                <img src="assets/googlemaps.png" alt="Google Maps" style={{ maxWidth: "28px", marginRight: "2px" }} />
              </a>
              </>: <></>}
              
            </Box>

            {/* Contenedor para el ID centrado */}
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
              {selectedOrder.ids ?    
              selectedOrder.ids?.map((item, index) => (
                <Typography key={item || index} variant="subtitle1">
                  <strong>{item}</strong>
                </Typography>
              ))
              :
              <Typography variant="subtitle1">
                <strong>{selectedOrder.id}</strong>
              </Typography>

              }
           
            </Box>
          </Box>
          <Box sx={{textAlign: "left" , pl: 0}}>    

          {selectedOrder.index !== 999? <Typography variant="body2"><strong>Parada:</strong> {selectedOrder.index || "Sin Ruta"}</Typography>: <> Sin Ruta </>}
          {selectedOrder.toAddress ? <Typography variant="body2"><strong>Destino:</strong> {selectedOrder.toAddress || "Sin Dirección"}</Typography>: <></>}
          </Box>
          <Box sx={{textAlign:  selectedOrder.status === 8 || selectedOrder.currentStatus === 8 ? "center" : "left", pl: 0}}> 
          {selectedOrder.ids ? <Typography variant="body2"><strong>Cantidad:</strong> {selectedOrder.ids.length}  {selectedOrder.ids.length > 1 ? "Butos" : "Bulto"} </Typography>: <></>}
          </Box>

          <Box sx={{textAlign:  selectedOrder.status === 8 || selectedOrder.currentStatus === 8 ? "center" : "left", pl: 3}}>  
          <Typography variant="body2" sx={{color : colorByStatus[selectedOrder.status ? selectedOrder.status : selectedOrder.currentStatus] || "#03033"}}><strong>Estado:</strong> {translateStatesBicciFilter[selectedOrder.status ? selectedOrder.status : selectedOrder.currentStatus] || "Desconocido"}</Typography>
          </Box>
          </Box>
            <Button
              size="small"
              variant="outlined"
              onClick={() => handleNavigate()}
              sx={{ fontSize: "0.6em"}}
              >
              🗺️ Ver en Lista
              </Button>
          </Box>
  )
}

export default ItemsPopup