import React from 'react'
import MapComponentMonitorStatusLeaflet from './MapComponentMonitorStatusLeaflet/MapComponentMonitorStatusLeaflet'

const MiRoute = () => {
  return (
    <MapComponentMonitorStatusLeaflet
   
    ></MapComponentMonitorStatusLeaflet>
  )
}

export default MiRoute