import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Box, Button, AppBar, Paper } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from '../Logo/Logo';
import Movilnavbar from '../Movilnavbar/Movilnavbar';

const Navbar = (props) => {
  const activo = useSelector((store) => store.usuario.activo);

  React.useEffect(() => {}, [activo]);

  return (
    <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, zIndex: 1000 }} elevation={3}>
     <AppBar
     
      elevation={0}
      sx={{
        backgroundColor: 'white',
        height: '80px',
        width:'100%',
        zIndex: 1300, // Asegura que quede sobre otros elementos
        borderBottom: '1px solid #ddd',
      }}
    >
      <Grid container alignItems="center" id="navbarconntainer" sx={{ px: 2 }}>
        {/* Logo */}
        <Grid item xs={6} sm={2} md={2} sx={{ display: 'flex', alignItems: 'center' }}>
          <Link to="/">
            <Logo />
          </Link>
        </Grid>

        {/* Centro: Menú móvil */}
        <Grid
          item
          xs={6}
          sm={8}
          md={8}
          sx={{ display: 'flex', justifyContent: 'flex-end',  }}
        >
          <Movilnavbar props={props} />
        </Grid>

        {/* Social Icons */}
        {/* <Grid
          item
          xs={12} sm={2} md={2}
          sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
        >
          <Box
            id="socialcontainer"
            sx={{
              display: { xs: 'none', sm: 'flex' }, // Solo visible en sm o superior
              justifyContent: 'center',
              gap: 1,
            }}
          >
            <Button
              component={Link}
              to="#"
              startIcon={<FacebookIcon />}
              sx={{
                color: 'purple',
                backgroundColor: 'transparent',
                ':hover': { backgroundColor: '#f0f0f0' },
              }}
            />
            <Button
              component={Link}
              to="#"
              startIcon={<InstagramIcon />}
              sx={{
                color: 'purple',
                backgroundColor: 'transparent',
                ':hover': { backgroundColor: '#f0f0f0' },
              }}
            />
          </Box>
        </Grid> */}
      </Grid>
    </AppBar>
    </Paper>
  );
  
};

export default Navbar;
