import { Routes, Route } from "react-router-dom";
import Micuenta from "./Micuenta";
import BuscarRutas from "../../../components/BuscarRutas/BuscarRutas";
import HistorialViajes from "./Sections/HistorialViajes";
import DetallesCuenta from "./Sections/DetallesCuenta";
import Desempeno from "./Sections/Desempeno";
import CarnetRider from "./Sections/CarnetRider";
import ViajesActivos from "./Sections/ViajesActivos";
import MapaViajesActivos from "./Sections/MapaOrdersPendientes";


const MicuentaRoutes = () => {
  return (
      <Routes>
      {/* `Micuenta` actúa como layout para las rutas hijas */}
      <Route path="/" element={<Micuenta />}>
        <Route path="viajes-activos" element={<ViajesActivos />} />
        <Route path="mapa-viajes-activos" element={<MapaViajesActivos />} />
        <Route path="buscar-rutas" element={<BuscarRutas />} />
        <Route path="historial-viajes" element={<HistorialViajes />} />
        <Route path="detalles-cuenta" element={<DetallesCuenta />} />
        <Route path="desempeno" element={<Desempeno />} />
        <Route path="carnet-rider" element={<CarnetRider />} />
      </Route>
    </Routes>
  );
};

export default MicuentaRoutes;
