import React, { useContext } from 'react'
import { ScreenSizeContext } from '../../context/ScreenSizeProvider';
import MobileFooter from '../MobileFooter/MobileFooter';

const Footer = () => {
    const { movilActive } = useContext(ScreenSizeContext);
 
  return movilActive ? <MobileFooter></MobileFooter>: (
    <div className='footter '><div>Copyright 2022 © BICCI  </div><a href='https://bicci.cl/terminos-y-condiciones/'> Términos y condiciones</a> </div>
  )
}

export default Footer