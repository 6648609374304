import React, { createContext, useState, useEffect, useMemo } from "react";
import { auth } from "../firebase";
import { onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";

export const UserContext = createContext();

function UserProvider({ children }) {
  const [user, setUser] = useState(null); // Estado del usuario
  const [loading, setLoading] = useState(true); // Estado de carga para evitar errores

  useEffect(() => {
    // Establecer la persistencia antes de escuchar los cambios de autenticación
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
          setUser(firebaseUser || null); 
          setLoading(false);
        });

        return () => unsubscribe(); // Cleanup al desmontar
      })
      .catch((error) => {
        console.error("Error setting persistence:", error);
        setLoading(false);
      });
  }, []);

  // Valor del contexto memorizado para mejorar rendimiento
  const userValue = useMemo(() => ({ user, setUser }), [user]);



  if (loading) return <div>Loading...</div>; // Evita mostrar contenido antes de validar sesión

  return (
    <UserContext.Provider value={userValue}>
      {children}
    </UserContext.Provider>
  );
}

export default UserProvider;
