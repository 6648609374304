import  { useEffect, useState } from 'react'
import {
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  onSnapshot
} from "firebase/firestore";
import { db } from '../firebase';

const useRutasDelDia = (userUid) => {
   
      const [userData, setUserData] = useState({});
 
      const [ordersRiderPemding, setOrdersRiderPemding] = useState([]);

      const [routes, setRoutes] = useState([]);

      const [mergedOrders, setMergedOrders] = useState([]);

       useEffect(() => {
        if (routes.length > 0 || ordersRiderPemding.length > 0) {
            const merged = mergeWaypointsIntoOrders(ordersRiderPemding, routes);
            setMergedOrders(merged);
          }
        }, [routes, ordersRiderPemding]);

        useEffect(() => {
          if (userUid) {
            let userUnsubscribe;
            let routesUnsubscribe;
            let ordersUnsubscribe;

            // Suscripción a datos del usuario
            userUnsubscribe = onSnapshot(doc(db, "drivers", userUid), (doc) => {
              if (doc.exists()) setUserData(doc.data());
            });

            // Suscripción a rutas
            routesUnsubscribe = subscribeToRoutes(userUid);
            ordersUnsubscribe = subscribeToPendingOrders(userUid)
            // fetchUserData(userUid);
            // getOrdersRouteForDay(userUid);
            getOrdersPending(userUid);
            //  getOrdersForDay("D9YG7bYIMgct0ri50iVAORHc1Xe2");

          return () => {
              userUnsubscribe?.();
              routesUnsubscribe?.();
            };
          }
        }, [userUid]);

        const getOrdersPending = async (uid) => {
          try {
            const collRef = collection(db, "orders");
            const q = query(collRef, 
              where("driverID", "==", uid), 
              where("status", ">", 0),
              where("status", "<", 8));
            const requestUser = await getDocs(q);
            const requestItems = requestUser.docs.map((doc) => ({
              id: doc.id, // ID del documento en Firestore
              ...doc.data()
            }));
            setOrdersRiderPemding(requestItems);
          } catch (e) {
            console.error("Error al recibir documentos de cliente ", e);
          }
        };

        const subscribeToRoutes = (uid) => {
            function getChileDateStamp() {
              const nowInChile = new Date(
                new Date().toLocaleString("en-US", { timeZone: "America/Santiago" })
              );

              const year = nowInChile.getFullYear();
              const month = String(nowInChile.getMonth() + 1).padStart(2, '0');
              const day = String(nowInChile.getDate()).padStart(2, '0');
              return `${year}${month}${day}`;
            }


  
            const today =  getChileDateStamp();

            const q = query(
              collection(db, "xpress_deliveryPlans"),
              where("driverID", "==", uid),
              where("dayStart", "==", today)
            );

            return onSnapshot(q, (snapshot) => {
              const routesData = [];
              const docUnsubscribes = [];

              snapshot.docChanges().forEach((change) => {
                const doc = change.doc;
                const docId = doc.id;

                if (change.type === "added" || change.type === "modified") {
                  // Suscribirse a actualizaciones del documento principal
                  const unsubscribeDoc = onSnapshot(doc.ref, (docSnap) => {
                    setRoutes(prev => updateRouteData(prev, docId, docSnap.data()));
                  });

                  // Suscribirse a waypoints
                  const unsubscribeWaypoints = onSnapshot(
                    collection(doc.ref, "waypoints"),
                    (snapshot) => {
                      const waypoints = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
                      setRoutes(prev => updateRouteData(prev, docId, { waypoints }));
                    }
                  );

                  // Suscribirse a observaciones
                  const unsubscribeObservations = onSnapshot(
                    collection(doc.ref, "observations"),
                    (snapshot) => {
                      const observations = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
                      setRoutes(prev => updateRouteData(prev, docId, { observations }));
                    }
                  );

                  docUnsubscribes.push(() => {
                    unsubscribeDoc();
                    unsubscribeWaypoints();
                    unsubscribeObservations();
                  });

                  routesData.push({
                    id: docId,
                    ...doc.data(),
                    waypoints: [],
                    observations: []
                  });
                }

                if (change.type === "removed") {
                  setRoutes(prev => prev.filter(route => route.id !== docId));
                }
              });

              // Actualizar estado inicial
              setRoutes(prev => [...prev.filter(r => !snapshot.docs.some(d => d.id === r.id)), ...routesData]);
            });
        };

        const updateRouteData = (prevRoutes, routeId, newData) => {
              return prevRoutes.map(route => {
                if (route.id === routeId) {
                  return { ...route, ...newData };
                }
                return route;
              });
        };

        const subscribeToPendingOrders = (uid) => {
            const q = query(
              collection(db, "orders"),
              where("driverID", "==", uid), 
              where("status", ">", 0),
              where("status", "<", 8)
            );

            return onSnapshot(q, (snapshot) => {
              const orders = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              setOrdersRiderPemding(orders);
            });
        };

        const mergeWaypointsIntoOrders = (orders, routes) => {
        const mergedOrders = [];
        const waypointIDs = new Set();

        // 🔹 1. Recorremos los waypoints y fusionamos con órdenes si existen
        routes.forEach(route => {
          route.waypoints?.forEach(wp => {
            waypointIDs.add(wp.id); // ✅ Guardamos los IDs que vienen de rutas

            const wpExtraData = {
              currentStatus: wp.currentStatus,
              driverID: wp.driverID || route.driverID,
              index: wp.index || 999,
              location: wp.location,
              routeName: wp.routeName || route.id,
              routingData: wp.routingData || {},
              trackingData: wp.trackingData || {},
              waypointInfo: true
            };

            const existing = orders.find(order => order.id === wp.id);

            if (existing) {
              mergedOrders.push({
                ...existing,
                ...wpExtraData
              });
            } else {
              mergedOrders.push({
                id: wp.id,
                ...wpExtraData
              });
            }
          });
        });

        // 🔹 2. Agregamos las órdenes que no están en rutas
        let i = mergedOrders.length + 1;
        orders.forEach(order => {
          if (!waypointIDs.has(order.id)) {
         
            mergedOrders.push({
              ...order,
              index: i++, // 👈 Agregas index progresivo
              waypointInfo: false
            });
          }
        });

        return mergedOrders;
      };



  



   

  const dataInfo = { "orders" : mergedOrders , "routes" : routes , "userData": userData}

  return  {dataInfo}
  
}

export default useRutasDelDia



        // const getOrdersRouteForDay = async (uid) => {
        //   try {
        //     const today = new Date();
        //     const todayLocal = new Date(
        //       today.getFullYear(),
        //       today.getMonth(),
        //       today.getDate()
        //     )
        //       .toISOString()
        //       .slice(0, 10)
        //       .replace(/-/g, ""); // Formato YYYYMMDD

        //     // 🔹 Obtener rutas del día para el conductor
        //     const rutasQuery = query(
        //       collection(db, "xpress_deliveryPlans"),
        //       where("driverID", "==", uid),
        //       where("dayStart", "==", todayLocal)
        //     );

        //     const querySnapshot = await getDocs(rutasQuery);

        //     const rutasData = await Promise.all(
        //       querySnapshot.docs.map(async (doc) => {
        //         const rutaData = { id: doc.id, ...doc.data() };

        //         // 🔹 Obtener `waypoints` de la subcolección en tiempo real
        //         const waypointsRef = collection(doc.ref, "waypoints");
        //         const waypointsSnapshot = await getDocs(waypointsRef);
        //         const waypoints = waypointsSnapshot.docs.map((wp) => ({
        //           id: wp.id,
        //           ...wp.data(),
        //         }));

        //         // 🔹 Obtener `observations` de la subcolección en tiempo real
        //         const observationsRef = collection(doc.ref, "observations");
        //         const observationsSnapshot = await getDocs(observationsRef);
        //         const observations = observationsSnapshot.docs.map((obs) => ({
        //           id: obs.id,
        //           ...obs.data(),
        //         }));

        //         return { ...rutaData, waypoints, observations };
        //       })
        //     );

        //     setRoutes(rutasData);
        //   } catch (e) {
        //     console.error("Error al recibir rutas del conductor ", e);
        //   }
        // };




        
        // const fetchUserData = async (uid) => {
        //   try {
        //     const userCollectionRef = collection(db, "drivers");
        //     const userDocRef = doc(userCollectionRef, uid);
        //     const userSnapshot = await getDoc(userDocRef);

        //     if (userSnapshot.exists()) {
        //       const data = userSnapshot.data();
        //       setUserData(data);
        
        //     }

        //   } catch (error) {
        //     console.error("Error fetching user data:", error);
        //   }
        // };

