import React from "react";
import { Box, LinearProgress } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const DetallesCuenta = () => {
  const { userData } = useOutletContext();
  return userData ? (
    <Box>
      <table className="table">
        <tbody>
          <tr>
            <th scope="row">Nombre</th>
            <td>{userData.name || "No especificado"}</td>
          </tr>
          <tr>
            <th scope="row">ID</th>
            <td>{userData.bicciNumber || "No especificado"}</td>
          </tr>
          <tr>
            <th scope="row">Email</th>
            <td>{userData.email || "No especificado"}</td>
          </tr>
          <tr>
            <th scope="row">Teléfono</th>
            <td>{userData.phone || "No especificado"}</td>
          </tr>
          <tr>
            <th scope="row">Dirección</th>
            <td>{userData.address || "No especificado"}</td>
          </tr>
          <tr>
            <th scope="row">DNI</th>
            <td>{userData.dni || "No especificado"}</td>
          </tr>
          <tr>
            <th scope="row">Fecha de alta</th>
            <td>{userData.createdAt || "No especificado"}</td>
          </tr>
        </tbody>
      </table>
    </Box>
  ): <LinearProgress></LinearProgress>;
};

export default DetallesCuenta;
